import React, {ReactNode} from 'react'
import {Dropdown} from '../index'
import {BsThreeDots} from 'react-icons/bs'
import {IoChevronBackCircleSharp} from 'react-icons/io5'
import {useNavigate} from 'react-router-dom'

type PropsType = {
    hasBackIcon?: boolean
    onBackIconClick?: () => void
    hasBorderBottom?: boolean
    menusLimit?: number
    className?: string
    title?: string | ReactNode
    description?: string | ReactNode
    rightMenus?: { icon: ReactNode, title: string, onClick: () => void, permissions: boolean, className?: string }[]
}


const PageTitleMenu = (props: PropsType) => {
    const {
        hasBackIcon = true,
        onBackIconClick,
        title,
        description,
        rightMenus,
        menusLimit = 3,
        hasBorderBottom = true,
        className
    } = props
    let dotsMenus: any[] = []
    if (rightMenus && rightMenus?.length > menusLimit) {
        rightMenus.slice(menusLimit).map((item) => {
            dotsMenus.push({type: 'custom', menuItem: item})
        })
    }
    const navigate = useNavigate()


    return (
        <div
            className={`${className} flex flex-row justify-between items-center ${hasBorderBottom ? 'border-b-2  border-b-white' : ''} pb-4`}>
            <div className='font-normal text-xl'>
                <div className='text-cyan-600 font-bold text-2xl flex  flex-row items-center'>
                    {title && hasBackIcon &&
                        <IoChevronBackCircleSharp onClick={() => onBackIconClick ? onBackIconClick() : navigate(-1)}

                                                  className='mr-4 cursor-pointer text-4xl text-cyan-500'/>}
                    <div>
                        {title}
                        {description && <p className='text-xs text-slate-600'>{description}</p>}
                    </div>
                </div>

            </div>
            <div>
                {rightMenus ? (
                    <div className='flex flex-row items-center bg-white shadow text-black font-semibold'>
                        {rightMenus.slice(0, menusLimit).map((item, index: number) => item.permissions ? (
                            <div onClick={item.onClick}
                                 key={index}
                                 className={`flex flex-row items-center font-semibold transition-all duration-200 ease-in-out cursor-pointer py-[7px] px-4 hover:bg-gray-700 hover:text-white border-r border-r-gray-300 ${item.className} `}>
                                <span className='pr-2'>{item.icon}</span>
                                {item.title ? <span className='text-[13px]'>{item.title}</span> : null}
                            </div>
                        ) : null)}
                        {dotsMenus.length ? <Dropdown
                            title='Options'
                            position='right'
                            menus={[dotsMenus]}
                            type='custom'
                            titleComponent={
                                <div className='flex items-center cursor-pointer py-[7px] px-4 '>
                                    <BsThreeDots/>
                                </div>
                            }
                        /> : null}
                    </div>) : null}
            </div>
        </div>
    )
}

export default PageTitleMenu
