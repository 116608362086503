import {useEffect} from "react";
import {appPermissions, perturbationsKeys, perturbationsTypes, routes} from "../../constants";
import { BlockingLoader, LineInfo, PageTitleMenu} from "../../components";
import {useLocation, useNavigate} from "react-router-dom";
import {useModalConfirm} from "../../contexts";
import {BsArrowsAngleExpand, BsPencil, BsTrash} from "react-icons/bs";
import {AppToast, HasPermissions} from "../../helpers";
import { UseUpdatePerturbation} from "../../services";
import {formatDate, formatDateTime, getDateDiffWithMoment} from "../../utils";

type PropsType = {
    item?: any
    onDelete?: () => void
    isModal?: boolean
}


const PerturbationDetail = (props: PropsType) => {
    const {onDelete, isModal} = props
    const navigate = useNavigate()
    const {state} = useLocation()
    const item = props?.item || state?.item
    const {modalConfirm} = useModalConfirm()

    const {isLoading: isDeleting, mutate: deleteData, data: deleteResult} = UseUpdatePerturbation()

    const headMenus = [
        {
            title: "Modifier",
            onClick: () => navigate(`${routes.perturbationsUpdate}/${item.id}`, {state: {item}}),
            permissions: HasPermissions(appPermissions.perturbations, "updater"),
            className: 'bg-gray-600 hover:bg-gray-800 text-white',
            icon: <BsPencil/>
        },
        {
            title: "Supprimer",
            onClick: () => deleteItem(),
            permissions: HasPermissions(appPermissions.feeders, "deleter"),
            icon: <BsTrash/>,
            className: 'bg-red-600 hover:bg-red-800 text-white'
        },
    ]

    useEffect(() => {
        if (deleteResult) {
            if (deleteResult.error) {
                AppToast.error(deleteResult?.message)
                return
            } else {
                AppToast.success("Perturbation supprimée avec succès")
                if (onDelete) onDelete()
                if(!isModal) navigate(`${routes.perturbationsList}/${item.type}`, {state: {refetch: true}})
                return;
            }
        }
    }, [deleteResult])

    const deleteItem = async () => {
        if (await modalConfirm()) {
            deleteData({id: item.id, status: "-1"})
        }
    }

    const getSelectedTypeTitle = () => {
        if (item.type === perturbationsTypes.depart) return item.depart_title
        if (item.type === perturbationsTypes.cabine) return item.cabine_title
        if (item.type === perturbationsTypes.feeder) return item.feeder_title
        if (item.type === perturbationsTypes.substation) return item.sub_station_title
        return ""
    }

    const getIndispoTime = () => {
        if(item.fixed_at){
            const diff = getDateDiffWithMoment(item.appeared_at, item.fixed_at)
            return `${diff.days}jr ${diff.hours}h ${diff.minutes}m`
        }
        return ""
    }

    return (
        <div>
            <PageTitleMenu
                title={<span className="font-light text-slate-800">Détail Pertrubation</span>}
                rightMenus={isModal ? [
                    ...headMenus,
                    ...[{
                        title: "Agrandir",
                        onClick: () => navigate(`${routes.perturbationsDetail}/${item.id}`, {state: {item}}),
                        permissions: HasPermissions(appPermissions.perturbations, "detailer"),
                        icon: <BsArrowsAngleExpand/>,
                    }]] : headMenus}
                hasBackIcon={!isModal}
            />

            <BlockingLoader isLoading={isDeleting} label="Exécution..."/>


            <table className="mt-4">
                <tbody>
                <LineInfo titleClass="!text-left" title={perturbationsKeys[item.type]} description={getSelectedTypeTitle()}/>
                <LineInfo titleClass="!text-left" title="Date Apparution" description={formatDateTime(item.appeared_at)}/>
                <LineInfo titleClass="!text-left" title="Date Remise" description={formatDateTime(item.fixed_at)}/>
                <LineInfo titleClass="!text-left" title="Temps Indisponibilité" description={getIndispoTime()}/>
                <LineInfo titleClass="!text-left" title="Nature" description={item.nature_title}/>
                <LineInfo titleClass="!text-left" title="Cause" description={item.cause_title}/>
                <LineInfo titleClass="!text-left" title="Organe" description={item.organism}/>
                <LineInfo titleClass="!text-left" title="Entité" description={item.agents}/>
                <LineInfo titleClass="!text-left" title="Travaux" description={item.works}/>
                <LineInfo titleClass="!text-left" title="Retabli" description={item.result}/>
                <LineInfo titleClass="!text-left" title="Enregistré le" description={formatDate(item?.created_at)}/>
                </tbody>
            </table>
        </div>
    )
}


export default PerturbationDetail
