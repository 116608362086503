import {useEffect} from "react";
import {appPermissions, routes} from "../../constants";
import {AppCheckBox, BlockingLoader, DataTable, ItemStatus, LineInfo, PageTitleMenu} from "../../components";
import {useLocation, useNavigate} from "react-router-dom";
import {useModalConfirm} from "../../contexts";
import {BsArrowsAngleExpand, BsArrowUpRight, BsPencil, BsTrash} from "react-icons/bs";
import {AppToast, HasPermissions} from "../../helpers";
import {UseGetCabines, UseUpdateCabine} from "../../services";
import {formatDate} from "../../utils";
import {DepartsList} from "../index";
import {RowPropsType} from "../../types";

type PropsType = {
    item?: any
    onDelete?: () => void
    onEditClick?: (item?: any) => void
    isModal?: boolean
}
const CabineDepartTableRow = (props: RowPropsType) => {
    const {isOdd, item, onItemClick, selectedItems, onSelectItem} = props

    return (
        <tr
            className={`cursor-pointer appTrHover text-[13px] ${isOdd ? 'appTrOdd' : 'appTr'}`}>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd font-semibold text-blue-500">{item.title}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.longer}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.section_cable}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.capacity_nominal}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.capacity_required}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.capacity_got}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.rate}</td>
            <td className="appTd text-center"><ItemStatus item={item}/></td>
        </tr>
    )
}

const CabineDetail = (props: PropsType) => {
    const {onDelete, onEditClick, isModal} = props
    const navigate = useNavigate()
    const {state} = useLocation()
    const item = props?.item || state?.item
    const {modalConfirm} = useModalConfirm()

    const {isLoading: isDeleting, mutate: deleteData, data: deleteResult} = UseUpdateCabine()
    const {isLoading: isGettingCabines, data: cabines} = UseGetCabines({feeder_id: item?.id})

    const headMenus = [
        {
            title: "Modifier",
            onClick: () => navigate(`${routes.cabinesUpdate}/${item.id}`, {state: {item}}),
            permissions: HasPermissions(appPermissions.cabines, "updater"),
            className: 'bg-gray-600 hover:bg-gray-800 text-white',
            icon: <BsPencil/>
        },
        {
            title: "Supprimer",
            onClick: () => deleteItem(),
            permissions: HasPermissions(appPermissions.cabines, "deleter"),
            icon: <BsTrash/>,
            className: 'bg-red-600 hover:bg-red-800 text-white'
        },
    ]
    const cabineTableHead = [
        {title: "Désignation"},
        {title: "Longeur (m)"},
        {title: "Section Cable"},
        {title: "Intens. Nominale (Amp)"},
        {title: "Intens. Requise (Amp)"},
        {title: "Intens. Atteinte (Amp)"},
        {title: "Taux (%)"},
        {title: "Statut", className: "w-[100px]"},
    ]

    useEffect(() => {
        if (deleteResult) {
            if (deleteResult.error) {
                AppToast.error(deleteResult?.message)
                return
            } else {
                AppToast.success("Cabine supprimée avec succès")
                if (onDelete) onDelete()
                if (!isModal) navigate(routes.cabinesList, {state: {refetch: true}})
                return;
            }
        }
    }, [deleteResult])

    const deleteItem = async () => {
        if (await modalConfirm()) {
            deleteData({id: item.id, status: "-1"})
        }
    }

    return (
        <div>
            <PageTitleMenu
                title={<span><span className="font-light text-slate-800">Cabine | </span> {item.title}</span>}
                rightMenus={isModal ? [
                    ...headMenus,
                    ...[{
                        title: "Agrandir",
                        onClick: () => navigate(`${routes.cabinesDetail}/${item.id}`, {state: {item}}),
                        permissions: HasPermissions(appPermissions.cabines, "deleter"),
                        icon: <BsArrowsAngleExpand/>,
                    }]] : headMenus}
                hasBackIcon={!isModal}
            />

            <BlockingLoader isLoading={isDeleting} label="Exécution..."/>

            <div className={`grid ${isModal ? 'grid-cols-1' : 'grid-cols-3'} gap-x-22`}>

                <div className="col-span-1">
                    <table className="mt-4">
                        <tbody>
                        <LineInfo titleClass="!text-left" title="Désignation" description={item.title}/>
                        <LineInfo titleClass="!text-left" title="Direction" description={item.direction_title}/>
                        <LineInfo titleClass="!text-left" title="Sous station" description={item.substation_title}/>
                        <LineInfo titleClass="!text-left" title="Feeder" description={item.feeder_title}/>
                        <LineInfo titleClass="!text-left" title="CVS" description={item.cvs_title}/>
                        <LineInfo titleClass="!text-left" title="Puissance/KVA" description={item.power}/>
                        <LineInfo titleClass="!text-left" title="Tension/KV" description={item.tension}/>
                        <LineInfo titleClass="!text-left" title="Type" description={item.type}/>
                        <LineInfo titleClass="!text-left" title="Regime" description={item.regime}/>
                        <LineInfo titleClass="!text-left" title="Adresse"
                                  description={`${item.street} Q/${item.quarter} C/${item.commune}`}/>
                        <LineInfo titleClass="!text-left" title="Statut" description={<ItemStatus item={item}/>}/>
                        <LineInfo titleClass="!text-left" title="Créé le" description={formatDate(item?.created_at)}/>
                        </tbody>
                    </table>
                    <div className="mb-2 font-bold mt-4">Etat de la Cabine</div>
                    <table className="mt-4">
                        <tbody>
                        <LineInfo titleClass="!text-left" title="Etat de la cabine" description={item.general_state}/>
                        <LineInfo titleClass="!text-left" title="Pavement" description={item.floor_state}/>
                        <LineInfo titleClass="!text-left" title="Toiture" description={item.roof_state}/>
                        <LineInfo titleClass="!text-left" title="Aération" description={item.aeration}/>
                        <LineInfo titleClass="!text-left" title="Cable de sortie" description={item.cable_state}/>
                        </tbody>
                    </table>
                    <div className="mb-2 font-bold mt-4">MT</div>
                    <table className="mt-4">
                        <tbody>
                        <LineInfo titleClass="!text-left" title="Protection MT" description={item.mt_protection}/>
                        <LineInfo titleClass="!text-left" title="Marque MT" description={item.mt_mark}/>
                        <LineInfo titleClass="!text-left" title="Calibre MT" description={item.mt_calibre}/>
                        </tbody>
                    </table>
                    <div className="mb-2 font-bold mt-4">BT</div>
                    <table className="mt-4">
                        <tbody>
                        <LineInfo titleClass="!text-left" title="Protection BT" description={item.bt_protection}/>
                        <LineInfo titleClass="!text-left" title="Marque BT" description={item.bt_mark}/>
                        <LineInfo titleClass="!text-left" title="Calibre BT" description={item.bt_calibre}/>
                        </tbody>
                    </table>
                </div>

                <div className="col-span-2">
                    <div className="mt-6">
                        <DepartsList
                            cabine={item}
                            cabineHeadMenus={
                                [{
                                    title: "Voir tout",
                                    onClick: () => navigate(routes.departsList),
                                    permissions: HasPermissions(appPermissions.departs),
                                    icon: <BsArrowUpRight/>,
                                }]
                            }
                            TableRow={CabineDepartTableRow}
                            cabineTableHead={cabineTableHead}
                        />
                    </div>


                    <div className="mt-6">
                        <PageTitleMenu
                            title="Les Pertubations de la Cabine"
                            rightMenus={isModal ? [{
                                title: "Agrandir",
                                onClick: () => navigate(`${routes.cabinesDetail}/${item.id}`, {state: {item}}),
                                permissions: HasPermissions(appPermissions.cabines, "deleter"),
                                icon: <BsArrowsAngleExpand/>,
                            }] : undefined}
                            hasBackIcon={false}
                        />
                    </div>
                </div>

            </div>


        </div>
    )
}


export default CabineDetail
