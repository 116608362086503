import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {
    BlockingModal, DataTable, DetailSideView,
    PageTitleMenu,
} from "../../components";
import {appPermissions, routes} from "../../constants";
import {BsPlusCircle} from "react-icons/bs";
import {AppToast, HasPermissions} from "../../helpers";
import {FaFileCsv, FaFileImport} from "react-icons/fa6";
import {FeederDetail, FeederForm, FeederTableRow} from "../index";
import {FormActionType, ItemType} from "../../types";
import {
    UseChangeFeedersStatus,
    UseGetFeeders,
} from "../../services";
import {useModalConfirm} from "../../contexts";


const FeedersList = () => {
    const navigate = useNavigate()
    const {modalConfirm} = useModalConfirm()
    const {state} = useLocation()

    const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false)
    const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false)
    const [selectedFormAction, setSelectedFormAction] = useState<FormActionType>("add")
    const [selectedItem, setSelectedItem] = useState<ItemType>()
    const [selectedItems, setSelectedItems] = useState<number[] | string[]>([])
    const [check, setCheck] = useState<boolean>(false)

    const {isLoading: isDataLoading, data: itemData, refetch: reGetData} = UseGetFeeders()
    const {isLoading: isChangingStatus, mutate: changeStatus, data: changeStatusResult} = UseChangeFeedersStatus()

    const headMenus = [
        {
            title: "Ajouter un Feeder",
            onClick: () => {
                setSelectedFormAction("add")
                setIsFormModalOpen(true)
            },
            permissions: HasPermissions(appPermissions.feeders, "creator"),
            icon: <BsPlusCircle/>,
            className: 'bg-green-600 hover:bg-green-800 text-white',
        },
        {
            title: 'Importer fichier',
            onClick: () => console.log(""),
            permissions: HasPermissions(appPermissions.feeders, "exporter"),
            icon: <FaFileImport/>,
        },
        {
            title: 'Exporter sous CSV',
            onClick: () => console.log(""),
            permissions: HasPermissions(appPermissions.feeders, "exporter"),
            icon: <FaFileCsv/>,
        },
    ]
    const tableHead = [
        {title: "Désignation"},
        {title: "Direction"},
        {title: "Sous station"},
        {title: "Catégorie"},
        {title: "Section"},
        {title: "Intens. Nominale"},
        {title: "Commentaire"},
        {title: "Statut", className: "w-[100px]"},
    ]

    useEffect(() => {
        if(state?.refetch) reGetData()
    }, [state])


    useEffect(() => {
        if (changeStatusResult) {
            changeStatusResult.error ? AppToast.error(changeStatusResult?.message) : AppToast.success("Action effectuée avec succès.")
            reGetData()
            setSelectedItems([])
            return
        }
    }, [changeStatusResult])

    const onMassDelete = async () => {
        if (await modalConfirm()) {
            changeStatus({
                ids: selectedItems,
                status: "-1"
            })
        }
    }

    const onValidateForm = async (newItem?: ItemType) => {
        setIsFormModalOpen(false)
        await reGetData()
        if (newItem) {
            setSelectedItem(newItem)
        }
    }

    const onSelectItem = (id: never) => {
        let temp = selectedItems
        const index = temp.indexOf(id);
        if (index > -1) temp.splice(index, 1);
        else temp.push(id);
        setSelectedItems(temp)
        setCheck(!check)
    }

    const onItemClick = (item: ItemType) => {
        setSelectedItem(item)
        setIsDetailOpen(true)
    }

    const onEditClick = (item: any) => {
        setSelectedFormAction("update")
        setSelectedItem(item)
        setIsFormModalOpen(true)
    }


    return (
        <div>
            <PageTitleMenu
                title="Tous les Feeders"
                onBackIconClick={() => navigate(routes.home)}
                rightMenus={headMenus}
            />

            <DataTable
                tableHead={tableHead}
                data={itemData?.data}
                isLoading={isDataLoading}
                onItemClick={onItemClick}
                onSelectItem={onSelectItem}
                selectedItems={selectedItems}
                onMassDelete={onMassDelete}
                isSelectionButtonLoading={isChangingStatus}
                TableRow={FeederTableRow}
            />

            <BlockingModal
                isOpen={isFormModalOpen}
                onClose={() => setIsFormModalOpen(false)}
                content={
                    <div className='w-[550px]'>
                        <FeederForm
                            onValidate={onValidateForm}
                            item={selectedItem}
                            action={selectedFormAction}
                            isModal
                        />
                    </div>
                }
            />

            <DetailSideView
                isOpen={isDetailOpen}
                onClose={() => {
                    setIsDetailOpen(false)
                    setSelectedFormAction("add")
                }}
                children={
                    <FeederDetail
                        item={selectedItem}
                        onDelete={async () => {
                            await reGetData()
                            setIsDetailOpen(false)
                        }}
                        onEditClick={onEditClick}
                        isModal
                    />
                }
                viewWidth="w-[50%]"
            />
        </div>
    )
}

export default FeedersList
