import {FormatUrlParams, GetQueryKey} from "../../utils";
import {useMutation, useQuery} from "react-query";
import {http} from "../index";
import {cacheTime, endpoints} from "../../constants";
import {ChangeStatusBodyType, DefaultBodyType} from "../../types";

type OptionsType = {
    id?: string | number
    direction_id?: string | number
    substation_id?: string | number
    feeder_id?: string | number
    status?: string
}


export const UseGetCvs = (options?: OptionsType) =>
    useQuery(GetQueryKey("cvs", options), async () => http.get(`${endpoints.cvs}${FormatUrlParams(options)}`), {staleTime: cacheTime.long});

export const UseAddCvs = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.cvs, body));


export const UseUpdateCvs = () =>
    useMutation(async (body: DefaultBodyType) => http.post(`${endpoints.cvs}/${body.id}`, body));

export const UseImportCvs = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.cvsImport, body, { headers: {'content-type': 'multipart/form-data'}}));

export const UseChangeCvsStatus = () =>
    useMutation(async (body: ChangeStatusBodyType) => http.post(endpoints.cvsChangeStatus, body));
