import {AppButton, AppDatePicker, AppInput, AppSelect, PageTitleMenu} from "../../components";
import {FormActionType, ItemType, PertubationTypesType} from "../../types";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    UseAddPerturbation,
    UseGetCabines,
    UseGetDeparts, UseGetFeeders, UseGetPerturbationCauses, UseGetPerturbationNatures,
    UseGetSubStations,
    UseUpdatePerturbation
} from "../../services";
import {perturbationsKeys, perturbationsTypes, routes} from "../../constants";
import {Controller, FieldValues, useForm} from "react-hook-form";
import {useEffect} from "react";
import {AppToast} from "../../helpers";
import {checkPerturbationType, formatDateTime, getMinuteDiff, reverseDate} from "../../utils";

type PropsType = {
    action?: FormActionType
    item?: ItemType
    type?: PertubationTypesType
}

const PerturbationForm = (props: PropsType) => {
    const {action} = props
    const isUpdate = action === 'update'
    const {state} = useLocation()
    const navigate = useNavigate()
    const params = useParams()

    const item = props?.item || state?.item
    const type: PertubationTypesType = state?.type || params?.type || props?.type || item?.type
    checkPerturbationType(type)

    const {isLoading: isAdding, mutate: addItem, data: addResult} = UseAddPerturbation()
    const {isLoading: isUpdating, mutate: updateItem, data: updateResult} = UseUpdatePerturbation()
    const {isLoading: isGettingNatures, data: natures} = UseGetPerturbationNatures()
    const {isLoading: isGettingCauses, data: causes} = UseGetPerturbationCauses()

    const getConcernedData = () => {
        if (type === perturbationsTypes.depart) return UseGetDeparts()
        if (type === perturbationsTypes.cabine) return UseGetCabines()
        if (type === perturbationsTypes.feeder) return UseGetFeeders()
        return UseGetSubStations()
    }
    const getSelectedTypeId = () => {
        if (type === perturbationsTypes.depart) return "depart_id"
        if (type === perturbationsTypes.cabine) return "cabine_id"
        if (type === perturbationsTypes.feeder) return "feeder_id"
        if (type === perturbationsTypes.substation) return "sub_station_id"
        return ""
    }

    const getSelectedTypeTitle = () => {
        if(isUpdate){
            if (type === perturbationsTypes.depart) return item.depart_title
            if (type === perturbationsTypes.cabine) return item.cabine_title
            if (type === perturbationsTypes.feeder) return item.feeder_title
            if (type === perturbationsTypes.substation) return item.sub_station_title
        }
        return ""
    }

    const {isLoading: isGettingTypeData, data: typeData} = getConcernedData()

    const {
        control,
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        mode: 'onSubmit', defaultValues: {
            type_data: getSelectedTypeTitle(),
            cause: isUpdate && item ? item?.cause_title : '',
            nature: isUpdate && item ? item?.nature_title : '',
            appeared_at: isUpdate && item ? item?.appeared_at : '',
            fixed_at: isUpdate && item ? item?.fixed_at : '',
            provenance: isUpdate && item ? item?.provenance : '',
            destination: isUpdate && item ? item?.destination : '',
            works: isUpdate && item ? item?.works : '',
            agents: isUpdate && item ? item?.agents : '',
            organism: isUpdate && item ? item?.organism : '',
            result: isUpdate && item ? item?.result : '',
        },
    })

    useEffect(() => {
        if (addResult) {
            if (addResult.error) {
                AppToast.error(addResult.message)
                return
            } else {
                AppToast.success("Perturbation enregistrée avec succès !")
                navigate(`${routes.perturbationsList}/${type}`, {state: {refetch: true}})
                return
            }
        }
    }, [addResult])

    useEffect(() => {
        if (updateResult) {
            if (updateResult.error) {
                AppToast.error(updateResult.message)
                return
            } else {
                AppToast.success("Perturbation modifiée avec succès !")
                navigate(`${routes.perturbationsList}/${type}`, {state: {refetch: true}})
                return
            }
        }
    }, [updateResult])


    const onSubmit = (data: FieldValues) => {
        const getDateData = (key: string) => {
            return  (isUpdate && item[key] && item[key] === data[key]) ? item[key] : formatDateTime(data[key], "en")
        }

        const body = {
            ...data,
            nature: null,
            cause: null,
            type_data: null,
            [getSelectedTypeId()]: data.type_data.id,
            cause_id: data?.cause.id,
            nature_id: data?.nature.id,
            type: type,
            appeared_at: getDateData("appeared_at"),
            fixed_at: data?.fixed_at ? getDateData("fixed_at") : null,
            duration: data?.fixed_at ? getMinuteDiff(getDateData("appeared_at"), getDateData("fixed_at")) : 0,
        }
        const {type_data, cause, nature, ...rest} = body;
        if (isUpdate) updateItem({...rest, id: item?.id})
        else addItem(rest)
    }

    return (
        <div>
            <PageTitleMenu
                title={<span>{isUpdate ? 'Modifier' : 'Ajouter'} une  Perturbation {perturbationsKeys[type]}</span>}
            />
            <form className='mt-4 grid grid-cols-3 gap-x-14' onSubmit={handleSubmit(onSubmit)}>
                <div className="col-span-1">
                    <div className="text-xl mb-4 font-bold">{perturbationsKeys[type]}</div>
                    <Controller
                        control={control}
                        name="type_data"
                        rules={{
                            required: {value: true, message: "Selectionnez un entité"},
                        }}
                        render={({field}) => (
                            <AppSelect
                                {...field}
                                label={perturbationsKeys[type]}
                                name="type_data"
                                required
                                text="title"
                                value="id"
                                selectedValue={isUpdate ? getSelectedTypeTitle() : ""}
                                isLoading={isGettingTypeData}
                                data={typeData?.data}
                                error={!!(errors && errors["type_data"])}
                                errorMessage={errors["type_data"]?.message?.toString()}
                            />
                        )}
                    />
                </div>
                <div className="col-span-1">
                    <div className="text-xl mb-4 font-bold">Detail de l'incident</div>
                    <Controller
                        control={control}
                        name='appeared_at'
                        rules={{
                            required: { value: true, message: "Entrez la date d'apparution" },
                        }}
                        render={({ field }) => (
                            <AppDatePicker
                                {...field}
                                defaultValue={isUpdate ? formatDateTime(item.appeared_at, "fr"): ""}
                                required
                                label="Date et heure d'apparution"
                                name='appeared_at'
                                errorMessage={errors['appeared_at']?.message?.toString()}
                                enableTime
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="nature"
                        rules={{
                            required: {value: true, message: "Selectionnez une nature"},
                        }}
                        render={({field}) => (
                            <AppSelect
                                {...field}
                                label="Nature"
                                name="nature"
                                required
                                text="title"
                                value="id"
                                selectedValue={isUpdate ? item?.nature_title : ""}
                                isLoading={isGettingNatures}
                                data={natures?.data}
                                error={!!(errors && errors["nature"])}
                                errorMessage={errors["nature"]?.message?.toString()}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="cause"
                        rules={{
                            required: {value: true, message: "Selectionnez une cause"},
                        }}
                        render={({field}) => (
                            <AppSelect
                                {...field}
                                label="Cause"
                                name="cause"
                                required
                                text="title"
                                value="id"
                                selectedValue={isUpdate ? item?.cause_title : ""}
                                isLoading={isGettingCauses}
                                data={causes?.data}
                                error={!!(errors && errors["cause"])}
                                errorMessage={errors["cause"]?.message?.toString()}
                            />
                        )}
                    />
                    {type === perturbationsTypes.substation && <AppInput
                        {...register('organism', {required: "Entrez l'organe"})}
                        type='text'
                        name='organism'
                        label='Organe'
                        error={!!(errors && errors['organism'])}
                        errorMessage={errors['organism']?.message?.toString()}
                        required
                    />}

                </div>
                <div className="col-span-1">
                    <div className="text-xl mb-4 font-bold">Reparation de l'incident</div>
                    <Controller
                        control={control}
                        name='fixed_at'
                        render={({ field }) => (
                            <AppDatePicker
                                {...field}
                                defaultValue={isUpdate && item.fixed_at ? formatDateTime(item.fixed_at, "fr"): ""}
                                label="Date et heure de remise"
                                name='fixed_at'
                                enableTime
                            />
                        )}
                    />
                    <AppInput
                        {...register('agents')}
                        type='text'
                        name='agents'
                        label='Entité'
                    />

                    <AppInput
                        {...register('works')}
                        type='text'
                        name='works'
                        label='Travaux effectués'
                        isTextarea
                        rows={1}
                    />
                    <AppInput
                        {...register('result')}
                        type='text'
                        name='result'
                        label='Retabli a combine de %'
                        leftIcon={<span className="absolute right-0 top-0 text-sm font-bold">%</span>}
                    />

                    <div className='text-center mt-5'>
                        <AppButton
                            type='submit'
                            label='Valider'
                            bgColor='bg-cyan-600 hover:bg-cyan-900'
                            className="w-full"
                            isLoading={isAdding || isUpdating}
                        />
                    </div>

                </div>
            </form>
        </div>
    )
}

export default PerturbationForm
