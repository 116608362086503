import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {
    BlockingModal, DataTable, DetailSideView,
    PageTitleMenu,
} from "../../components";
import {appPermissions, routes} from "../../constants";
import {BsPlusCircle} from "react-icons/bs";
import {AppToast, HasPermissions} from "../../helpers";
import {FaFileCsv, FaFileImport} from "react-icons/fa6";
import {DepartTableRow, DepartForm, DepartDetail} from "../index";
import {FormActionType, ItemType} from "../../types";
import {
    UseChangeDepartsStatus,
    UseGetDeparts,
} from "../../services";
import {useModalConfirm} from "../../contexts";


type PropsType = {
    cabine?: ItemType
    cabineHeadMenus?: any
    TableRow?: any
    cabineTableHead?: any
}

const DepartsList = (props: PropsType) => {
    const {cabine, cabineHeadMenus, TableRow, cabineTableHead} = props
    const navigate = useNavigate()
    const {modalConfirm} = useModalConfirm()
    const {state} = useLocation()

    const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false)
    const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false)
    const [selectedFormAction, setSelectedFormAction] = useState<FormActionType>("add")
    const [selectedItem, setSelectedItem] = useState<ItemType>()
    const [selectedItems, setSelectedItems] = useState<number[] | string[]>([])
    const [check, setCheck] = useState<boolean>(false)

    const {isLoading: isDataLoading, data: itemData, refetch: reGetData} = UseGetDeparts({cabine_id: cabine?.id})
    const {isLoading: isChangingStatus, mutate: changeStatus, data: changeStatusResult} = UseChangeDepartsStatus()

    const headMenus = [
        {
            title: "Ajouter un Depart",
            onClick: () => {
                setSelectedFormAction("add")
                setIsFormModalOpen(true)
            },
            permissions: HasPermissions(appPermissions.departs, "creator"),
            icon: <BsPlusCircle/>,
            className: 'bg-green-600 hover:bg-green-800 text-white',
        },
        {
            title: 'Importer fichier',
            onClick: () => console.log(""),
            permissions: HasPermissions(appPermissions.departs, "exporter"),
            icon: <FaFileImport/>,
        },
        {
            title: 'Exporter sous CSV',
            onClick: () => console.log(""),
            permissions: HasPermissions(appPermissions.departs, "exporter"),
            icon: <FaFileCsv/>,
        },
    ]
    const tableHead = [
        {title: "Désignation"},
        {title: "Cabine"},
        {title: "Longeur (m)"},
        {title: "Section Cable"},
        {title: "Intens. Nominale (Amp)"},
        {title: "Intens. Requise (Amp)"},
        {title: "Intens. Atteinte (Amp)"},
        {title: "Taux (%)"},
        {title: "Statut", className: "w-[100px]"},
    ]

    useEffect(() => {
        if (state?.refetch) reGetData()
    }, [state])


    useEffect(() => {
        if (changeStatusResult) {
            changeStatusResult.error ? AppToast.error(changeStatusResult?.message) : AppToast.success("Action effectuée avec succès.")
            reGetData()
            setSelectedItems([])
            return
        }
    }, [changeStatusResult])

    const onMassDelete = async () => {
        if (await modalConfirm()) {
            changeStatus({
                ids: selectedItems,
                status: "-1"
            })
        }
    }

    const onValidateForm = async (newItem?: ItemType) => {
        setIsFormModalOpen(false)
        await reGetData()
        if (newItem) {
            setSelectedItem(newItem)
        }
    }

    const onSelectItem = (id: never) => {
        let temp = selectedItems
        const index = temp.indexOf(id);
        if (index > -1) temp.splice(index, 1);
        else temp.push(id);
        setSelectedItems(temp)
        setCheck(!check)
    }

    const onItemClick = (item: ItemType) => {
        setSelectedItem(item)
        setIsDetailOpen(true)
    }

    const onEditClick = (item: any) => {
        setSelectedFormAction("update")
        setSelectedItem(item)
        setIsFormModalOpen(true)
    }


    return (
        <div>
            <PageTitleMenu
                title={`${cabine ? "Departs de la Cabine" : "Tous les Départs"} (${itemData?.data?.length})`}
                rightMenus={cabine ? cabineHeadMenus : headMenus}
                hasBackIcon={!cabine}
            />

            <DataTable
                tableHead={cabineTableHead || tableHead}
                data={itemData?.data}
                isLoading={isDataLoading}
                onItemClick={onItemClick}
                onSelectItem={cabine ? undefined : onSelectItem}
                selectedItems={selectedItems}
                onMassDelete={onMassDelete}
                isSelectionButtonLoading={isChangingStatus}
                TableRow={TableRow || DepartTableRow}
                hasMassAction={!cabine}
                hasSearchInput={!cabine}
            />

            <BlockingModal
                isOpen={isFormModalOpen}
                onClose={() => setIsFormModalOpen(false)}
                content={
                    <div className='w-[550px]'>
                        <DepartForm
                            onValidate={onValidateForm}
                            item={selectedItem}
                            action={selectedFormAction}
                            isModal
                        />
                    </div>
                }
            />

            <DetailSideView
                isOpen={isDetailOpen}
                onClose={() => {
                    setIsDetailOpen(false)
                    setSelectedFormAction("add")
                }}
                children={
                    <DepartDetail
                        item={selectedItem}
                        onDelete={async () => {
                            await reGetData()
                            setIsDetailOpen(false)
                        }}
                        onEditClick={onEditClick}
                        isModal
                    />
                }
                viewWidth="w-[50%]"
            />
        </div>
    )
}

export default DepartsList
