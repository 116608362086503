import {perturbationsTypes} from "../../constants";
import {RowPropsType} from "../../types";
import {AppCheckBox} from "../../components";
import {formatDateTime, getDateDiffWithMoment} from "../../utils";

const PerturbationTableRow = (props: RowPropsType) => {
    const {isOdd, item, onItemClick, selectedItems, onSelectItem} = props
    const checkIndex = (data: any, item: any) => {
        return data.indexOf(item)
    }
    const getSelectedTypeTitle = () => {
        if (item.type === perturbationsTypes.depart) return item.depart_title
        if (item.type === perturbationsTypes.cabine) return item.cabine_title
        if (item.type === perturbationsTypes.feeder) return item.feeder_title
        if (item.type === perturbationsTypes.substation) return item.sub_station_title
        return ""
    }

    const getIndispoTime = () => {
        if(item.fixed_at){
            const diff = getDateDiffWithMoment(item.appeared_at, item.fixed_at)
            return `${diff.days}jr ${diff.hours}h ${diff.minutes}m`
        }
        return ""
    }

    return (
        <tr
            className={`cursor-pointer appTrHover text-[13px] ${isOdd ? 'appTrOdd' : 'appTr'}`}>
            {onSelectItem ? <td className="appTd font-bold text-blue-500 border-b-4">
                <AppCheckBox
                    id={item.id}
                    isChecked={checkIndex(selectedItems, item.id) > -1}
                    onChange={onSelectItem}
                />
            </td> : null}
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd font-semibold text-blue-500">{getSelectedTypeTitle()}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{formatDateTime(item.appeared_at)}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{formatDateTime(item.fixed_at)}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{getIndispoTime()}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.nature_title}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.cause_title}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.organism}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.agents}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.works}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.result}</td>
        </tr>
    )
}

export default PerturbationTableRow
