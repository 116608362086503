import React, {forwardRef, LegacyRef} from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import moment from "moment/moment";
import { French } from "flatpickr/dist/l10n/fr.js"
import {BsCalendarWeek} from "react-icons/bs"

import {DateFormType} from "../../types";
import InputHelperMessage from "./InputHelperMessage";

const AppDatePicker: React.FC<DateFormType> = forwardRef((props: DateFormType, ref: LegacyRef<any>) => {
    const {
        name,
        label,
        required,
        value,
        placeholder,
        defaultValue,
        enableTime,
        errorMessage,
        onChange, ...rest} = props
    return (
        <div>
            {label && <label htmlFor={name}>{label} </label>}
            <div className="relative">
                <Flatpickr
                    name={name}
                    ref={ref}
                    {...rest}
                    className={`${errorMessage ? 'border-red-300 focus:outline-none' : 'border-slate-400'} appearance-none placeholder:text-gray-400  w-full rounded-sm border-2 px-2 py-1 bg-white`}
                    defaultValue={defaultValue}
                    onChange={(v) => onChange(moment(v[0].toISOString()).format(`DD-MM-YYYY${enableTime ? ' HH:mm': ''}`))}
                    options={{
                        dateFormat: `d-m-Y${enableTime ? ' H:i': ''}`,
                        altFormat: `d-m-Y${enableTime ? ' H:i': ''}`,
                        enableTime: enableTime,
                        locale: French
                    }}
                />
                <span className="absolute right-3 top-2.5 cursor-pointer"> <BsCalendarWeek/> </span>
            </div>
            <InputHelperMessage message={errorMessage} type="error"/>

        </div>
    )
})


export default AppDatePicker
