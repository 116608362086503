import {FormActionType, ItemType} from "../../types"
import {Controller, FieldValues, useForm} from "react-hook-form";
import {AppButton, AppInput, AppSelect, PageTitleMenu} from "../../components";
import {UseAddSubStation, UseGetDirections, UseUpdateSubStation} from "../../services";
import {useEffect} from "react";
import {AppToast} from "../../helpers";
import {useLocation, useNavigate} from "react-router-dom";
import {routes} from "../../constants";

type PropsType = {
    action?: FormActionType
    onValidate?: (newItem?: ItemType) => void
    item?: ItemType
    isModal?: boolean
}

const SubStationForm = (props: PropsType) => {
    const {action, onValidate, isModal} = props
    const isUpdate = action === 'update'
    const {state} = useLocation()
    const navigate = useNavigate()
    const item = props?.item || state?.item

    const {isLoading: isAdding, mutate: addItem, data: addResult} = UseAddSubStation()
    const {isLoading: isUpdating, mutate: updateItem, data: updateResult} = UseUpdateSubStation()
    const {isLoading: isGettingDirections, data: directions} = UseGetDirections()

    const {
        control,
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        mode: 'onSubmit', defaultValues: {
            title: isUpdate && item ? item?.title : '',
            description: isUpdate && item ? item?.description : '',
            direction: isUpdate && item ? item?.direction_id : '',
        },
    })

    useEffect(() => {
        if (addResult) {
            if (addResult.error) {
                AppToast.error(addResult.message)
                return
            } else {
                AppToast.success("Sous Station enregistrée avec succès !")
                if (onValidate) onValidate()
                if (!isModal) navigate(routes.substationsList, {state: {refetch: true}})
                return
            }
        }
    }, [addResult])

    useEffect(() => {
        if (updateResult) {
            if (updateResult.error) {
                AppToast.error(updateResult.message)
                return
            } else {
                AppToast.success("Sous Station modifiée avec succès !")
                if (onValidate) onValidate({...item, ...updateResult?.data})
                if(!isModal) navigate(routes.substationsList, {state: {refetch: true}})
                return
            }
        }
    }, [updateResult])

    const onSubmit = (data: FieldValues) => {
        const body = {
            title: data.title,
            description: data.description,
            direction_id: data.direction.id,
        }
        if (isUpdate) updateItem({...body, id: item?.id})
        else addItem(body)
    }

    return (
        <div>
            <PageTitleMenu
                title={<span>{isUpdate ? 'Modifier' : 'Ajouter'} une  Sous station</span>}
                hasBackIcon={!isModal}
            />
            <div className={`grid ${isModal ? 'grid-cols-1' : 'grid-cols-3'}`}>
                <div/>
                <form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
                    <AppInput
                        {...register('title', {required: 'Entrez la désignation'})}
                        type='text'
                        name='title'
                        label='Désignation'
                        error={!!(errors && errors['title'])}
                        errorMessage={errors['title']?.message?.toString()}
                        required
                    />

                    <Controller
                        control={control}
                        name="direction"
                        rules={{
                            required: {value: true, message: "Selectionnez une direction"},
                        }}
                        render={({field}) => (
                            <AppSelect
                                {...field}
                                label="Direction"
                                name="direction"
                                required
                                text="title"
                                value="id"
                                selectedValue={isUpdate ? item?.direction_title : ""}
                                isLoading={isGettingDirections}
                                data={directions?.data}
                                error={!!(errors && errors["direction"])}
                                errorMessage={errors["direction"]?.message?.toString()}
                            />
                        )}
                    />


                    <AppInput
                        {...register('description')}
                        type='text'
                        name='description'
                        label='Commentaires'
                        isTextarea
                    />

                    <div className='text-center mt-5'>
                        <AppButton
                            type='submit'
                            label='Valider'
                            bgColor='bg-cyan-600 hover:bg-cyan-900'
                            className="w-1/2"
                            isLoading={isAdding || isUpdating}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SubStationForm
