import {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    DataTable, DetailSideView,
    PageTitleMenu,
} from "../../components";
import {appPermissions, perturbationsKeys, routes} from "../../constants";
import {BsPlusCircle} from "react-icons/bs";
import {AppToast, HasPermissions} from "../../helpers";
import {FaFileCsv, FaFileImport} from "react-icons/fa6";
import { PerturbationDetail, PerturbationTableRow} from "../index";
import {ItemType, PertubationTypesType} from "../../types";
import {
     UseChangePerturbationsStatus,
    UseGetPerturbations,
} from "../../services";
import {useModalConfirm} from "../../contexts";
import {checkPerturbationType} from "../../utils";

type PropsType = {
    type?: PertubationTypesType
}

const PerturbationsList = (props: PropsType) => {
    const navigate = useNavigate()
    const {modalConfirm} = useModalConfirm()
    const {state} = useLocation()
    const params = useParams()

    const type: PertubationTypesType = state?.type || params.type || props?.type
    checkPerturbationType(type)

    const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false)
    const [selectedItem, setSelectedItem] = useState<ItemType>()
    const [selectedItems, setSelectedItems] = useState<number[] | string[]>([])
    const [check, setCheck] = useState<boolean>(false)

    const {isLoading: isDataLoading, data: itemData, refetch: reGetData} = UseGetPerturbations({type: type})
    const {isLoading: isChangingStatus, mutate: changeStatus, data: changeStatusResult} = UseChangePerturbationsStatus()

    const headMenus = [
        {
            title: `Ajouter une perturbation ${perturbationsKeys[type]}`,
            onClick: () => navigate(`${routes.perturbationsNew}/${type}`),
            permissions: HasPermissions(appPermissions.perturbations, "creator"),
            icon: <BsPlusCircle/>,
            className: 'bg-green-600 hover:bg-green-800 text-white',
        },
        {
            title: 'Importer fichier',
            onClick: () => console.log(""),
            permissions: HasPermissions(appPermissions.perturbations, "exporter"),
            icon: <FaFileImport/>,
        },
        {
            title: 'Exporter sous CSV',
            onClick: () => console.log(""),
            permissions: HasPermissions(appPermissions.perturbations, "exporter"),
            icon: <FaFileCsv/>,
        },
    ]

    const tableHead = [
        {title: perturbationsKeys[type]},
        {title: "Apparution"},
        {title: "Remise"},
        {title: "Indisponible"},
        {title: "Nature"},
        {title: "Cause"},
        {title: "Organe"},
        {title: "Entité"},
        {title: "Travaux"},
        {title: "Retabli"},
    ]

    useEffect(() => {
        if(state?.refetch) reGetData()
    }, [state])


    useEffect(() => {
        if (changeStatusResult) {
            changeStatusResult.error ? AppToast.error(changeStatusResult?.message) : AppToast.success("Action effectuée avec succès.")
            reGetData()
            setSelectedItems([])
            return
        }
    }, [changeStatusResult])

    const onMassDelete = async () => {
        if (await modalConfirm()) {
            changeStatus({
                ids: selectedItems,
                status: "-1"
            })
        }
    }

    const onSelectItem = (id: never) => {
        let temp = selectedItems
        const index = temp.indexOf(id);
        if (index > -1) temp.splice(index, 1);
        else temp.push(id);
        setSelectedItems(temp)
        setCheck(!check)
    }

    const onItemClick = (item: ItemType) => {
        setSelectedItem(item)
        setIsDetailOpen(true)
    }


    return (
        <div>
            <PageTitleMenu
                title={`Toutes les Perturbations ${perturbationsKeys[type]}`}
                onBackIconClick={() => navigate(routes.home)}
                rightMenus={headMenus}
            />

            <DataTable
                tableHead={tableHead}
                data={itemData?.data}
                isLoading={isDataLoading}
                onItemClick={onItemClick}
                onSelectItem={onSelectItem}
                selectedItems={selectedItems}
                onMassDelete={onMassDelete}
                isSelectionButtonLoading={isChangingStatus}
                TableRow={PerturbationTableRow}
            />

            <DetailSideView
                isOpen={isDetailOpen}
                onClose={() => setIsDetailOpen(false)}
                children={
                    <PerturbationDetail
                        item={selectedItem}
                        onDelete={async () => {
                            await reGetData()
                            setIsDetailOpen(false)
                        }}
                        isModal
                    />
                }
                viewWidth="w-[50%]"
            />
        </div>
    )
}


export default PerturbationsList
