import {SiPowerpages} from "react-icons/si";
import {FieldValues, useForm} from "react-hook-form";
import {AppButton, AppInput, Clock} from "../../components";
import {images} from "../../assets/images";
import {HiKey, HiUser} from "react-icons/hi2";
import {useEffect} from "react";
import {AppToast} from "../../helpers";
import {UseLogin} from "../../services";
import {setAuthData} from "../../utils";

const Login = () => {
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({mode: "all" });

    const {isLoading: isLogging, mutate: login, data: loginResult} = UseLogin()

    useEffect(() => {
        if (loginResult) {
            if (loginResult?.error) AppToast.error(loginResult?.message)
            else {
                setAuthData({
                    token: loginResult?.token,
                    user: loginResult?.user,
                    permissions: loginResult?.access || '',
                })
                window.location.href = "/"
            }
        }
    }, [loginResult])

    const onSubmit = (data: FieldValues) => login({username: data.username, password: data.password})


    return (
        <div
            style={{
                backgroundImage: `url("assets/login-bg.jpg")`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}
            className="relative">
            <div className="absolute right-4 top-4">
                <span className="bg-sky-600 text-white  px-1 rounded block font-bold"><Clock /></span>
            </div>
            <div className="absolute left-4 top-4">
                <span className="font-black text-xl"><SiPowerpages className="inline text-sky-600" /> GECAO </span>
            </div>

            <div className="h-screen flex flex-col justify-center items-center">

                <div className="flex justify-center items-center">
                    <img src={images.defaultAvatar} alt="" className="w-[90px] rounded-full"/>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className="mt-8 w-[30%]">
                    <AppInput
                        {...register("username", {required: "Entrez votre Nom Utilisateur"})}
                        name="username"
                        error={!!(errors && errors["username"])}
                        errorMessage={errors["username"]?.message?.toString()}
                        rightIcon={<HiUser className="text-sky-900" />}
                    />

                    <AppInput
                        {...register("password", {required: "Entrez votre Mot de passe"})}
                        name="password"
                        type="password"
                        error={!!(errors && errors["password"])}
                        errorMessage={errors["password"]?.message?.toString()}
                        containerClassName="mt-2"
                        rightIcon={<HiKey className="text-sky-900" />}
                    />

                    <AppButton
                        label="SE CONNECTER"
                        type="submit"
                        size="md"
                        className="w-[100%] mt-2 py-3 bg-sky-600 hover:bg-sky-800"
                        isLoading={isLogging}
                    />
                </form>

            </div>
        </div>
    )
}

export default Login
