import {FormatUrlParams, GetQueryKey} from "../../utils";
import {useMutation, useQuery} from "react-query";
import {http} from "../index";
import {cacheTime, endpoints} from "../../constants";
import {ChangeStatusBodyType, DefaultBodyType} from "../../types";

type OptionsType = {
    id?: string | number
    direction_id?: string | number
    substation_id?: string | number
    feeder_id?: string | number
    cvs_id?: string | number
    status?: string
}


export const UseGetCabines = (options?: OptionsType) =>
    useQuery(GetQueryKey("cabines", options), async () => http.get(`${endpoints.cabines}${FormatUrlParams(options)}`), {staleTime: cacheTime.long});

export const UseAddCabine = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.cabines, body));


export const UseUpdateCabine = () =>
    useMutation(async (body: DefaultBodyType) => http.post(`${endpoints.cabines}/${body.id}`, body));

export const UseImportCabines = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.cabinesImport, body, { headers: {'content-type': 'multipart/form-data'}}));

export const UseChangeCabinesStatus = () =>
    useMutation(async (body: ChangeStatusBodyType) => http.post(endpoints.cabinesChangeStatus, body));
