export const appPermissions = {
    none: '00',
    permissions: "1",
    users: "2",
    roles: "3",
    profiles: "4",
    logs: "5",
    directions: "6",
    substations: "7",
    feederCategories: "8",
    feeders: "9",
    feederPointes: "10",
    cvs: "11",
    cabines: "12",
    departs: "13",
    perturbationCauses: "14",
    perturbationsNatures: "15",
    perturbations: "16",
}

export default appPermissions
