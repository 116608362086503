export const endpoints = {
    logout: '/logout',
    login: '/login',
    users: '/api/users',
    userAccesses: "/api/user-access-functions",
    userChangeStatus: "/api/change-users-status",
    userChangePassword: "/api/user-change-password",
    userChangeFirstPassword: "/api/user-change-first-password",
    userLoginHistories: "/api/user-login-histories",
    directions: "/api/directions",
    directionsImport: "/api/import-directions",
    directionsChangeStatus: "/api/change-directions-status",
    substations: "/api/sub-stations",
    substationsImport: "/api/import-sub-stations",
    substationsChangeStatus: "/api/change-sub-stations-status",
    feeders: "/api/feeders",
    feedersImport: "/api/import-feeders",
    feedersChangeStatus: "/api/change-feeders-status",
    feederCategories: "/api/feeder-categories",
    feederCategoriesImport: "/api/import-feeder-categories",
    feederCategoriesChangeStatus: "/api/change-feeder-categories-status",
    cabines: "/api/cabines",
    cabinesImport: "/api/import-cabines",
    cabinesChangeStatus: "/api/change-cabines-status",
    departs: "/api/departs",
    departsImport: "/api/import-departs",
    departsChangeStatus: "/api/change-departs-status",
    cvs: "/api/cvs",
    cvsImport: "/api/import-cvs",
    cvsChangeStatus: "/api/change-cvs-status",
    perturbations: "/api/perturbations",
    perturbationsImport: "/api/import-perturbations",
    perturbationsChangeStatus: "/api/change-perturbations-status",
    perturbationsNatures: "/api/perturbation-natures",
    perturbationsNaturesImport: "/api/import-perturbation-natures",
    perturbationsNaturesChangeStatus: "/api/change-perturbation-natures-status",
    perturbationsCauses: "/api/perturbation-causes",
    perturbationsCausesImport: "/api/import-perturbation-causes",
    perturbationsCausesChangeStatus: "/api/change-perturbation-causes-status",
    releves: "/api/releves",
}

export default endpoints
