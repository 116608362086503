import {FormatUrlParams, GetQueryKey} from "../../utils";
import {useMutation, useQuery} from "react-query";
import {http} from "../index";
import {cacheTime, endpoints} from "../../constants";
import {ChangeStatusBodyType, DefaultBodyType, PertubationTypesType} from "../../types";

type OptionsType = {
    id?: string | number
    type?: PertubationTypesType
    sub_station_id?: string | number
    feeder_id?: string | number
    depart_id?: string | number
    cabine_id?: string | number
    nature_id?: string | number
    cause_id?: string | number
    status?: string
}


export const UseGetPerturbations = (options?: OptionsType) =>
    useQuery(GetQueryKey("Perturbations", options), async () => http.get(`${endpoints.perturbations}${FormatUrlParams(options)}`), {staleTime: cacheTime.long});

export const UseAddPerturbation = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.perturbations, body));


export const UseUpdatePerturbation = () =>
    useMutation(async (body: DefaultBodyType) => http.post(`${endpoints.perturbations}/${body.id}`, body));

export const UseImportPerturbations = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.perturbationsImport, body, { headers: {'content-type': 'multipart/form-data'}}));

export const UseChangePerturbationsStatus = () =>
    useMutation(async (body: ChangeStatusBodyType) => http.post(endpoints.perturbationsChangeStatus, body));


export const UseGetPerturbationCauses = (options?: OptionsType) =>
    useQuery(GetQueryKey("PerturbationCauses", options), async () => http.get(`${endpoints.perturbationsCauses}${FormatUrlParams(options)}`), {staleTime: cacheTime.long});

export const UseAddPerturbationCause = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.perturbationsCauses, body));

export const UseUpdatePerturbationCause = () =>
    useMutation(async (body: DefaultBodyType) => http.post(`${endpoints.perturbationsCauses}/${body.id}`, body));

export const UseImportPerturbationCauses = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.perturbationsCausesImport, body, { headers: {'content-type': 'multipart/form-data'}}));

export const UseChangePerturbationCausesStatus = () =>
    useMutation(async (body: ChangeStatusBodyType) => http.post(endpoints.perturbationsCausesChangeStatus, body));


export const UseGetPerturbationNatures = (options?: OptionsType) =>
    useQuery(GetQueryKey("PerturbationNatures", options), async () => http.get(`${endpoints.perturbationsNatures}${FormatUrlParams(options)}`), {staleTime: cacheTime.long});

export const UseAddPerturbationNature = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.perturbationsNatures, body));

export const UseUpdatePerturbationNature = () =>
    useMutation(async (body: DefaultBodyType) => http.post(`${endpoints.perturbationsNatures}/${body.id}`, body));

export const UseImportPerturbationNature = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.perturbationsNaturesImport, body, { headers: {'content-type': 'multipart/form-data'}}));

export const UseChangePerturbationNaturesStatus = () =>
    useMutation(async (body: ChangeStatusBodyType) => http.post(endpoints.perturbationsNaturesChangeStatus, body));
