import {createContext, PropsWithChildren, useCallback, useContext, useRef, useState} from "react";
import {DeleteModal} from "../components";

const defaultFunc: (p?: any, cb?: Function) => Promise<boolean> = (p?: any) => {
    return Promise.resolve(true)
}
const defaultValue = {
    confirmRef: {
        current: defaultFunc
    }
}

const ConfirmContext = createContext(defaultValue)

const ConfirmContextProvider = ({children}: PropsWithChildren) => {
    const confirmRef = useRef(defaultFunc);
    return (
        <ConfirmContext.Provider value={{confirmRef}}>
            {children}
            <ConfirmDialogue/>
        </ConfirmContext.Provider>
    )
}


const ConfirmDialogue = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [props, setProps] = useState<any | undefined>();
    const resolveRef = useRef((v: boolean) => {})
    const {confirmRef} = useContext(ConfirmContext)
    confirmRef.current = async (props) => {
        return new Promise((resolve) => {
            setProps(props)
            setIsOpen(true)
            resolveRef.current = resolve
        })
    }



    return <DeleteModal
        isOpen={isOpen}
        onCancel={() => {
            resolveRef.current(false)
            setIsOpen(false)
        }}
        onValidate={() => {
            resolveRef.current(true)
            setIsOpen(false)
        }}
        {...props}
    />
}

export function useModalConfirm() {
    const {confirmRef} = useContext(ConfirmContext)
    return {
        modalConfirm: useCallback((p?: any, cb?: Function) => {
            return confirmRef.current(p, cb)
        }, [confirmRef])
    }
}

export default ConfirmContextProvider
