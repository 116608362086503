import {appPermissions, localStorageKeys} from '../constants/'
import {AppStorage} from '../utils'
import {PermissionActionType} from "../types/";

const HasPermissions = (permission:  string, action: PermissionActionType = "reader") => {
    //const {currentUser: {permissions: userPermissions}} = useContext(UserContext);
    let userPerms = AppStorage.getItem(localStorageKeys.permissions)
    const userPermissions = userPerms ? JSON.parse(userPerms) : []
    if (permission === appPermissions.none) return true
    if (userPermissions) {
        if (userPermissions[permission] && userPermissions[permission][action]?.toString() === "1") return true
    }
    return false
}


export default HasPermissions
