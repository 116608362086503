import {BlockingOverlay, Spinner} from "../index";

type PropsType = {
    isLoading: boolean
    label?: string
}

const BlockingLoader = ({isLoading, label}: PropsType) => {
    return isLoading ? (
        <BlockingOverlay children={
            <div className="font-bold"><Spinner label={label}/></div>
        }/>
    ) : null
}

export default BlockingLoader
