import {RowPropsType} from "../../types";
import {AppCheckBox, ItemStatus} from "../../components";

const CabineTableRow = (props: RowPropsType) => {
    const {isOdd, item, onItemClick, selectedItems, onSelectItem} = props

    const checkIndex = (data: any, item: any) => {
        return data.indexOf(item)
    }

    return (
        <tr
            className={`cursor-pointer appTrHover text-[13px] ${isOdd ? 'appTrOdd' : 'appTr'}`}>
            {onSelectItem ? <td className="appTd font-bold text-blue-500 border-b-4">
                <AppCheckBox
                    id={item.id}
                    isChecked={checkIndex(selectedItems, item.id) > -1}
                    onChange={onSelectItem}
                />
            </td> : null}
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd font-semibold text-blue-500">{item.title}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.substation_title}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.feeder_title}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.cvs_title}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.power}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.tension}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.type}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.regime}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.street} Q/{item.quarter} C/{item.commune}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.general_state}</td>
            <td className="appTd text-center"><ItemStatus item={item}/></td>
        </tr>
    )
}

export default CabineTableRow
