import React, {ChangeEvent} from "react";

type CheckBoxPropsType = {
    id: any
    title?: string
    isChecked: boolean
    onChange: (id: string | number) => void
}


const AppCheckBox = (props: CheckBoxPropsType) => {
    const {id, title, isChecked, onChange} = props
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        onChange(id)
    }

    return (
        <label className="mt-1 cursor-pointer">
            <input type="checkbox" checked={isChecked} className="w-5 h-5 mt-1 cursor-pointer" onChange={(e) => handleChange(e)}/>
            {title && <div className="text-xs">{title}</div>}
        </label>
    )
}
export default AppCheckBox
