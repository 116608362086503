import React, {useState} from 'react';
import {ToastContainer} from 'react-toastify'

import './App.css'
import 'react-toastify/dist/ReactToastify.css'
import {Route, Routes, useLocation} from "react-router-dom";
import UserContextProvider from './contexts/UserContext'
import {ModalContextProvider} from './contexts'
import {LoggedRoute, ProtectedRoute} from './helpers'
import {
    CabineDetail,
    CabineForm,
    CabinesList,
    ChangePasswordModal, CvsDetail, CvsForm, CvsList,
    DepartDetail,
    DepartsList,
    DirectionDetail,
    DirectionForm,
    DirectionsList,
    FeederCategoriesList,
    FeederCategoryDetail,
    FeederCategoryForm,
    FeederDetail,
    FeederForm,
    FeedersList,
    Home,
    Login,
    PerturbationCauseDetail,
    PerturbationCauseForm,
    PerturbationCausesList, PerturbationDetail, PerturbationForm, PerturbationNatureDetail,
    PerturbationNatureForm,
    PerturbationNaturesList,
    PerturbationsList, RelevesForm,
    SubStationDetail,
    SubStationForm,
    SubStationsList
} from "./pages";
import {routes} from "./constants"
import {HeaderNavbar, SideBar} from "./components";
import DepartForm from "./pages/departs/DepartForm";

function App() {
    const location = useLocation()
    const unauthRoutes = [routes.login]
    const [showSideBar, setShowBar] = useState(false)


    return (
        <div className="transition duration-200 ease-in-out h-[100%]">
            <ModalContextProvider>
                <UserContextProvider>
                    {location && unauthRoutes.includes(location.pathname) ? (
                        <Routes>
                            <Route element={<LoggedRoute/>}>
                                <Route path={routes.login} element={<Login/>}/>
                            </Route>
                        </Routes>
                    ) : (
                        <div className="flex bg-slate-50  h-full">
                            <ChangePasswordModal/>
                            <SideBar isOpen={showSideBar} toggleSideBar={() => setShowBar(false)}/>
                            <div className="w-full bg-slate-50 h-full pb-10">
                                <HeaderNavbar toggleSideBar={() => setShowBar(!showSideBar)}/>
                                <div className="px-4 mt-16 pb-10">
                                    <Routes>
                                        <Route element={<ProtectedRoute/>}>
                                            <Route path={routes.home} element={<Home/>}/>

                                            <Route path={routes.directionsList} element={<DirectionsList/>}/>
                                            <Route path={routes.directionsNew} element={<DirectionForm/>}/>
                                            <Route path={`${routes.directionsUpdate}/:directionId`}
                                                   element={<DirectionForm action="update"/>}/>
                                            <Route path={`${routes.directionsDetail}/:directionId`}
                                                   element={<DirectionDetail/>}/>

                                            <Route path={routes.substationsList} element={<SubStationsList/>}/>
                                            <Route path={routes.substationsNew} element={<SubStationForm/>}/>
                                            <Route path={`${routes.substationsUpdate}/:substationId`}
                                                   element={<SubStationForm action="update"/>}/>
                                            <Route path={`${routes.substationsDetail}/:substationId`}
                                                   element={<SubStationDetail/>}/>

                                            <Route path={routes.feedersList} element={<FeedersList/>}/>
                                            <Route path={routes.feedersNew} element={<FeederForm/>}/>
                                            <Route path={`${routes.feedersUpdate}/:feederId`}
                                                   element={<FeederForm action="update"/>}/>
                                            <Route path={`${routes.feedersDetail}/:feederId`}
                                                   element={<FeederDetail/>}/>

                                            <Route path={routes.cabinesList} element={<CabinesList/>}/>
                                            <Route path={routes.cabinesNew} element={<CabineForm/>}/>
                                            <Route path={`${routes.cabinesUpdate}/:cabineId`}
                                                   element={<CabineForm action="update"/>}/>
                                            <Route path={`${routes.cabinesDetail}/:cabineId`}
                                                   element={<CabineDetail/>}/>

                                            <Route path={routes.departsList} element={<DepartsList/>}/>
                                            <Route path={routes.departsNew} element={<DepartForm/>}/>
                                            <Route path={`${routes.departsUpdate}/:departId`}
                                                   element={<DepartForm action="update"/>}/>
                                            <Route path={`${routes.departsDetail}/:departId`}
                                                   element={<DepartDetail/>}/>

                                            <Route path={`${routes.perturbationsList}`}
                                                   element={<PerturbationsList/>}/>
                                            <Route path={`${routes.perturbationsList}/:type`}
                                                   element={<PerturbationsList/>}/>
                                            <Route path={`${routes.perturbationsNew}/:type`} element={<PerturbationForm/>}/>
                                            <Route path={`${routes.perturbationsUpdate}/:perturbationId`}
                                                   element={<PerturbationForm action="update"/>}/>
                                            <Route path={`${routes.perturbationsDetail}/:perturbationId`}
                                                   element={<PerturbationDetail/>}/>

                                            <Route path={routes.feederCategoriesList}
                                                   element={<FeederCategoriesList/>}/>
                                            <Route path={routes.feederCategoriesNew} element={<FeederCategoryForm/>}/>
                                            <Route path={`${routes.feederCategoriesUpdate}/:departId`}
                                                   element={<FeederCategoryForm action="update"/>}/>
                                            <Route path={`${routes.feederCategoriesDetail}/:departId`}
                                                   element={<FeederCategoryDetail/>}/>

                                            <Route path={routes.cvsList} element={<CvsList/>}/>
                                            <Route path={routes.cvsNew} element={<CvsForm/>}/>
                                            <Route path={`${routes.cvsUpdate}/:cvsId`}
                                                   element={<CvsForm action="update"/>}/>
                                            <Route path={`${routes.cvsDetail}/:cvsId`} element={<CvsDetail/>}/>

                                            <Route path={routes.perturbationNaturesList}
                                                   element={<PerturbationNaturesList/>}/>
                                            <Route path={routes.perturbationNaturesNew}
                                                   element={<PerturbationNatureForm/>}/>
                                            <Route path={`${routes.perturbationNaturesUpdate}/:natureId`}
                                                   element={<PerturbationNatureForm action="update"/>}/>
                                            <Route path={`${routes.perturbationNaturesDetail}/:natureId`}
                                                   element={<PerturbationNatureDetail/>}/>

                                            <Route path={routes.perturbationCausesList}
                                                   element={<PerturbationCausesList/>}/>
                                            <Route path={routes.perturbationCausesNew}
                                                   element={<PerturbationCauseForm/>}/>
                                            <Route path={`${routes.perturbationCausesUpdate}/:causeId`}
                                                   element={<PerturbationCauseForm action="update"/>}/>
                                            <Route path={`${routes.perturbationCausesDetail}/:causeId`}
                                                   element={<PerturbationCauseDetail/>}/>

                                            <Route path={routes.releveNew} element={<RelevesForm/>}/>

                                        </Route>
                                    </Routes>
                                </div>
                            </div>

                        </div>
                    )}
                </UserContextProvider>
            </ModalContextProvider>
            <ToastContainer/>
        </div>
    );
}

export default App;
