import {toast} from "react-toastify";
const simple = (message: string) => toast(message)
const success =  (message: string) => toast.success(message)
const error =  (message: string) => toast.error(message)
const warning =  (message: string) => toast.warning(message)

const exps = {
    success,
    simple,
    error,
    warning
}
export default exps
