import {useContext, useEffect} from "react";
import { UserContext} from "../../contexts/UserContext";
import {AppButton, AppInput, BlockingOverlay} from "../../components";
import {useForm} from "react-hook-form";
import {AppToast} from "../../helpers";
import {removeAuthData} from "../../utils";
import {UseChangeFirstPassword} from "../../services";


const ChangePasswordModal = () => {
    const {currentUser: {user: connectedUser}} = useContext(UserContext)
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({mode: "all"});

    const {isLoading: isChanging, mutate: changePassword, data: changeResult} = UseChangeFirstPassword()

    useEffect(() => {
        if(changeResult){
            if(changeResult.error){
                AppToast.error(changeResult.message)
                return;
            }else{
                AppToast.success("Mot de passe mis a jour avec succès, Connectez-vous a nouveau.")
                setTimeout(()=> {
                    removeAuthData()
                    window.location.reload()
                }, 500)
            }
            return
        }
    }, [changeResult])

    const onSubmit = async (data:any) => {
        if(data.confirm_new_password !== data.new_password){
            AppToast.error("Les mots de passe ne correspondent pas")
            return
        }
        changePassword({
            id: connectedUser.id,
            ...data
        })
    }

    return connectedUser?.should_change_password?.toString() === "1" ? (
        <BlockingOverlay
            children={
                <div
                    className="relative bg-white rounded-lg border-t-4 border-t-black text-left overflow-hidden shadow-xl transform transition-all py-8 px-10 md:w-[40%] w-[98%]  ">

                    <div className="text-xl font-bold mb-8">Modifier votre mot de passe</div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <AppInput
                            {...register("new_password", {required: "Entrez le nouveau mot de passe"})}
                            label="Nouveau Mot de passe"
                            name="new_password"
                            type="password"
                            error={!!(errors && errors["new_password"])}
                            errorMessage={errors["new_password"]?.message?.toString()}
                        />

                        <AppInput
                            {...register("confirm_new_password", {required: "Confirmer le nouveau mot de passe"})}
                            label="Confirmer nouveau Mot de passe"
                            name="confirm_new_password"
                            type="password"
                            error={!!(errors && errors["confirm_new_password"])}
                            errorMessage={errors["confirm_new_password"]?.message?.toString()}
                        />

                        <div className="mt-4 flex justify-center">
                            <AppButton
                                type="submit"
                                label="Valider"
                                className="bg-blue-500 hover:bg-blue-700 w-[70%]"
                                isLoading={isChanging}
                            />
                        </div>
                    </form>
                </div>
            }
        />
    ): null
}

export default ChangePasswordModal
