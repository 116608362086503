const CryptoJS = require('crypto-js');
const {REACT_APP_ENCRYPT_TOKEN} = process.env

export const encryptData = (data: string) => {
    const ciphertext = CryptoJS.AES.encrypt(data, REACT_APP_ENCRYPT_TOKEN);
    return ciphertext.toString()
}

export const decryptData = (data: string) => {
    const bytes = CryptoJS.AES.decrypt(data, REACT_APP_ENCRYPT_TOKEN);
    return bytes.toString(CryptoJS.enc.Utf8);
}
