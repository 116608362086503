import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {FormActionType, ItemType} from "../../types"
import {Controller, FieldValues, useForm} from "react-hook-form";
import {AppButton, AppInput, AppSelect, PageTitleMenu} from "../../components";
import {UseAddDepart, UseGetCabines, UseUpdateDepart} from "../../services";
import {AppToast} from "../../helpers";
import {routes} from "../../constants";

type PropsType = {
    action?: FormActionType
    onValidate?: (newItem?: ItemType) => void
    item?: ItemType
    isModal?: boolean
}

const DepartForm = (props: PropsType) => {
    const {action, onValidate, isModal} = props
    const isUpdate = action === 'update'
    const {state} = useLocation()
    const navigate = useNavigate()
    const item = props?.item || state?.item

    const {isLoading: isAdding, mutate: addItem, data: addResult} = UseAddDepart()
    const {isLoading: isUpdating, mutate: updateItem, data: updateResult} = UseUpdateDepart()
    const {isLoading: isGettingCabines, data: cabines} = UseGetCabines()

    const {
        control,
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        mode: 'onSubmit', defaultValues: {
            title: isUpdate && item ? item?.title : '',
            cabine: isUpdate && item ? item?.cabine_id : '',
            longer: isUpdate && item ? item?.longer : '',
            section_cable: isUpdate && item ? item?.section_cable : '',
            capacity_nominal: isUpdate && item ? item?.capacity_nominal : '',
            capacity_required: isUpdate && item ? item?.capacity_required : '',
            capacity_got: isUpdate && item ? item?.capacity_got : '',
            rate: isUpdate && item ? item?.rate : '',
        },
    })

    useEffect(() => {
        if (addResult) {
            if (addResult.error) {
                AppToast.error(addResult.message)
                return
            } else {
                AppToast.success("Depart enregistré avec succès !")
                if (onValidate) onValidate()
                if (!isModal) navigate(routes.departsList, {state: {refetch: true}})
                return
            }
        }
    }, [addResult])

    useEffect(() => {
        if (updateResult) {
            if (updateResult.error) {
                AppToast.error(updateResult.message)
                return
            } else {
                AppToast.success("Depart modifié avec succès !")
                if (onValidate) onValidate({...item, ...updateResult?.data})
                if (!isModal) navigate(routes.departsList, {state: {refetch: true}})
                return
            }
        }
    }, [updateResult])

    const onSubmit = (data: FieldValues) => {
        const body = {
            ...data,
            cabine: null,
            cabine_id: data.cabine.id,
        }
        const { cabine, ...rest } = body;
        if (isUpdate) updateItem({...rest, id: item?.id})
        else addItem(rest)
    }

    return (
        <div>
            <PageTitleMenu
                title={<span>{isUpdate ? 'Modifier' : 'Ajouter'} un  Depart</span>}
                hasBackIcon={!isModal}
            />
            <div className={`grid ${isModal ? 'grid-cols-1' : 'grid-cols-3'}`}>
                <div/>
                <form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
                    <AppInput
                        {...register('title', {required: 'Entrez la désignation'})}
                        type='text'
                        name='title'
                        label='Désignation'
                        error={!!(errors && errors['title'])}
                        errorMessage={errors['title']?.message?.toString()}
                        required
                    />

                    <Controller
                        control={control}
                        name="cabine"
                        rules={{
                            required: {value: true, message: "Selectionnez une Cabine"},
                        }}
                        render={({field}) => (
                            <AppSelect
                                {...field}
                                label="Cabine"
                                name="cabine"
                                required
                                text="title"
                                value="id"
                                selectedValue={isUpdate ? item?.cabine_title : ""}
                                isLoading={isGettingCabines}
                                data={cabines?.data}
                                error={!!(errors && errors["cabine"])}
                                errorMessage={errors["cabine"]?.message?.toString()}
                            />
                        )}
                    />

                    <AppInput
                        {...register('longer', {required: 'Entrez la longueur'})}
                        type='text'
                        name='longer'
                        label='Longeur'
                        error={!!(errors && errors['longer'])}
                        errorMessage={errors['longer']?.message?.toString()}
                        required
                        leftIcon={<span className="absolute right-0 top-0 text-sm font-bold">m</span>}
                    />

                    <AppInput
                        {...register('section_cable', {required: "Entrez la section cable"})}
                        type='text'
                        name='section_cable'
                        label='Section Cable'
                        error={!!(errors && errors['section_cable'])}
                        errorMessage={errors['section_cable']?.message?.toString()}
                        required
                    />

                    <AppInput
                        {...register('capacity_nominal', {required: "Entrez l'intensité nominale"})}
                        type='text'
                        name='capacity_nominal'
                        label='Intensité nominale'
                        error={!!(errors && errors['capacity_nominal'])}
                        errorMessage={errors['capacity_nominal']?.message?.toString()}
                        leftIcon={<span className="absolute right-0 top-0 text-sm font-bold">Amp</span>}
                        required
                    />

                    <AppInput
                        {...register('capacity_required', {required: "Entrez l'intensité requise"})}
                        type='text'
                        name='capacity_required'
                        label='Intensité requise'
                        error={!!(errors && errors['capacity_required'])}
                        errorMessage={errors['capacity_required']?.message?.toString()}
                        leftIcon={<span className="absolute right-0 top-0 text-sm font-bold">Amp</span>}
                        required
                    />

                    <AppInput
                        {...register('capacity_got', {required: "Entrez l'intensité atteinte"})}
                        type='text'
                        name='capacity_got'
                        label='Intensité atteinte'
                        error={!!(errors && errors['capacity_got'])}
                        errorMessage={errors['capacity_got']?.message?.toString()}
                        leftIcon={<span className="absolute right-0 top-0 text-sm font-bold">Amp</span>}
                        required
                    />

                    <AppInput
                        {...register('rate')}
                        type='text'
                        name='rate'
                        label='Taux'
                        leftIcon={<span className="absolute right-0 top-0 text-sm font-bold">%</span>}
                    />

                    <div className='text-center mt-5'>
                        <AppButton
                            type='submit'
                            label='Valider'
                            bgColor='bg-cyan-600 hover:bg-cyan-900'
                            className="w-1/2"
                            isLoading={isAdding || isUpdating}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DepartForm
