import {appPermissions, hoursData, routes} from "../../constants";
import {
    AppDatePicker,
    AppInput,
    AppSelect,
    PageTitleMenu,
    TableLoader
} from "../../components";
import {HasPermissions} from "../../helpers";
import {FaFileCsv, FaFileImport, FaRegFaceSadTear} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {ItemType} from "../../types";
import {UseGetFeeders, UseGetSubStations} from "../../services";
import {useForm} from "react-hook-form";

const RelevesForm = () => {
    const navigate = useNavigate()
    const [selectedSubstation, setSelectSubstation] = useState<ItemType>()
    const [selectedDate, setSelectDate] = useState<Date | string>("")

    const {
        watch,
        register,
    } = useForm({  mode: 'onBlur'})

    const {isLoading: isGettingSubStations, data: substations} = UseGetSubStations()
    const {isLoading: isGettingFeeders, data: feeders} = UseGetFeeders({sub_station_id: selectedSubstation?.id})

    //const {isLoading: isGettingReleves, data: releves} = UseGetReleves({sub_station_id: selectedSubstation?.id, date: formatDate(selectedDate, "en")})
    //const {isLoading: isAdding, data: addReleve} = UseAddReleve()

    const headMenus = [
        {
            title: 'Importer fichier',
            onClick: () => console.log(""),
            permissions: HasPermissions(appPermissions.perturbations, "exporter"),
            icon: <FaFileImport/>,
        },
        {
            title: 'Exporter sous CSV',
            onClick: () => console.log(""),
            permissions: HasPermissions(appPermissions.perturbations, "exporter"),
            icon: <FaFileCsv/>,
        },
    ]

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            // if(data && name === "search"){
            //     const temp: ItemType[] = data?.filter((item:any)=>
            //         item?.title?.toLowerCase()?.includes(value?.search?.toLowerCase()) ||
            //         item?.description?.toLowerCase()?.includes(value?.search?.toLowerCase())
            //     )
            //     setStateData(temp)
            // }
        });
        return () => subscription.unsubscribe();
    }, [watch])

    return (
        <div>
            <PageTitleMenu
                title="Relevé de sous station"
                onBackIconClick={() => navigate(routes.home)}
                rightMenus={headMenus}
            />

            <div className="bg-white pt-2 grid md:grid-cols-8 grid-cols-1 gap-x-8 shadow-lg">
                <div className="col-span-2 col-start-3">
                    <AppSelect
                        label="Sous station"
                        name="substation"
                        text="title"
                        value="id"
                        selectedValue={""}
                        onChange={(v: any) => setSelectSubstation(v)}
                        isLoading={isGettingSubStations}
                        data={substations?.data}
                    />
                </div>
                <div className="col-span-2">
                    <AppDatePicker
                        label="Date"
                        name="selected_date"
                        defaultValue={selectedDate.toString()}
                        onChange={(value: Date) => {
                            setSelectDate(value)
                        }}
                        value={new Date(selectedDate)}
                        required
                    />
                </div>
            </div>


            {
                selectedSubstation && selectedDate ? (
                    <div className="mt-8">
                        <table className="w-[120%] appTable">
                            <thead>
                            <tr className="text-[14px] bg-gray-500 text-white">
                                <th className="appTh w-[100px]">FEEDER</th>
                                {hoursData.map((item) => (
                                    <th key={item.id} className="appTh text-center">{item.title}</th>))}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                feeders && feeders.data && feeders.data.map((feeder: ItemType, key: number) => (
                                    <tr
                                        key={key}
                                        className={`cursor-pointer appTrHover text-[13px] ${key%2 ===0 ? 'appTrOdd' : 'appTr'}`}>
                                        <td>{feeder.title}</td>
                                        {hoursData.map((hour) => (
                                            <td key={hour.id}>
                                                <AppInput
                                                    {...register(`rel_${hour.id}`)}
                                                    type='text'
                                                    name={`rel_${hour.id}`}
                                                    hasError={false}
                                                    onBlur={(v: any) => {
                                                        console.log(v)
                                                    }}
                                                />
                                            </td>
                                        ))}
                                    </tr>

                                ))
                            }
                            </tbody>
                        </table>
                        <TableLoader isLoading={isGettingFeeders}/>
                    </div>
                ) : (
                    <div className="flex flex-col justify-center items-center mt-20">
                        <FaRegFaceSadTear className="text-4xl text-red-900"/>
                        <p className="text-red-600 mt-2 text-center">Veuillez selectionnez une sous station et une
                            date <br/> pour entrer les données</p>
                    </div>
                )
            }
        </div>
    )
}

export default RelevesForm
