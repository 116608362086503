import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {
    DataTable, DetailSideView,
    PageTitleMenu,
} from "../../components";
import {appPermissions, routes} from "../../constants";
import {BsPlusCircle} from "react-icons/bs";
import {AppToast, HasPermissions} from "../../helpers";
import {FaFileCsv, FaFileImport} from "react-icons/fa6";
import {CabineDetail, CabineTableRow} from "../index";
import {ItemType} from "../../types";
import { UseChangeCabinesStatus, UseGetCabines} from "../../services";
import {useModalConfirm} from "../../contexts";


const CabinesList = () => {
    const navigate = useNavigate()
    const {modalConfirm} = useModalConfirm()
    const {state} = useLocation()

    const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false)
    const [selectedItem, setSelectedItem] = useState<ItemType>()
    const [selectedItems, setSelectedItems] = useState<number[] | string[]>([])
    const [check, setCheck] = useState<boolean>(false)

    const {isLoading: isDataLoading, data: itemData, refetch: reGetData} = UseGetCabines()
    const {isLoading: isChangingStatus, mutate: changeStatus, data: changeStatusResult} = UseChangeCabinesStatus()

    const headMenus = [
        {
            title: "Ajouter une Cabine",
            onClick: () => navigate(routes.cabinesNew),
            permissions: HasPermissions(appPermissions.directions, "creator"),
            icon: <BsPlusCircle/>,
            className: 'bg-green-600 hover:bg-green-800 text-white',
        },
        {
            title: 'Importer fichier',
            onClick: () => console.log(""),
            permissions: HasPermissions(appPermissions.directions, "exporter"),
            icon: <FaFileImport/>,
        },
        {
            title: 'Exporter sous CSV',
            onClick: () => console.log(""),
            permissions: HasPermissions(appPermissions.directions, "exporter"),
            icon: <FaFileCsv/>,
        },
    ]
    const tableHead = [
        {title: "Désignation"},
        {title: "Sous station"},
        {title: "Feeder"},
        {title: "CVS"},
        {title: "Puissance/KVA"},
        {title: "Tension/KV"},
        {title: "Type"},
        {title: "Regime"},
        {title: "Adresse"},
        {title: "Etat Cabine"},
        {title: "Statut", className: "w-[100px]"},
    ]

    useEffect(() => {
        if (state?.refetch) reGetData()
    }, [state])


    useEffect(() => {
        if (changeStatusResult) {
            changeStatusResult.error ? AppToast.error(changeStatusResult?.message) : AppToast.success("Action effectuée avec succès.")
            reGetData()
            setSelectedItems([])
            return
        }
    }, [changeStatusResult])

    const onMassDelete = async () => {
        if (await modalConfirm()) {
            changeStatus({
                ids: selectedItems,
                status: "-1"
            })
        }
    }


    const onSelectItem = (id: never) => {
        let temp = selectedItems
        const index = temp.indexOf(id);
        if (index > -1) temp.splice(index, 1);
        else temp.push(id);
        setSelectedItems(temp)
        setCheck(!check)
    }

    const onItemClick = (item: ItemType) => {
        setSelectedItem(item)
        setIsDetailOpen(true)
    }


    return (
        <div>
            <PageTitleMenu
                title="Toutes les Cabines"
                onBackIconClick={() => navigate(routes.home)}
                rightMenus={headMenus}
            />

            <DataTable
                tableHead={tableHead}
                data={itemData?.data}
                isLoading={isDataLoading}
                onItemClick={onItemClick}
                onSelectItem={onSelectItem}
                selectedItems={selectedItems}
                onMassDelete={onMassDelete}
                isSelectionButtonLoading={isChangingStatus}
                TableRow={CabineTableRow}
            />

            <DetailSideView
                isOpen={isDetailOpen}
                onClose={() => {
                    setIsDetailOpen(false)
                }}
                children={
                    <CabineDetail
                        item={selectedItem}
                        onDelete={async () => {
                            await reGetData()
                            setIsDetailOpen(false)
                        }}
                        isModal
                    />
                }
                viewWidth="w-[60%]"
            />
        </div>
    )
}

export default CabinesList
