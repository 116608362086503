import {FormatUrlParams, GetQueryKey} from "../../utils";
import {useMutation, useQuery} from "react-query";
import {http} from "../index";
import {cacheTime, endpoints} from "../../constants";
import {ChangeStatusBodyType, DefaultBodyType} from "../../types";

type OptionsType = {
    id?: string | number
    direction_id?: string | number
    substation_id?: string | number
    feeder_id?: string | number
    cabine_id?: string | number
    cvs_id?: string | number
    status?: string
}


export const UseGetDeparts = (options?: OptionsType) =>
    useQuery(GetQueryKey("departs", options), async () => http.get(`${endpoints.departs}${FormatUrlParams(options)}`), {staleTime: cacheTime.long});

export const UseAddDepart = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.departs, body));

export const UseUpdateDepart = () =>
    useMutation(async (body: DefaultBodyType) => http.post(`${endpoints.departs}/${body.id}`, body));

export const UseImportDeparts = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.departsImport, body, { headers: {'content-type': 'multipart/form-data'}}));

export const UseChangeDepartsStatus = () =>
    useMutation(async (body: ChangeStatusBodyType) => http.post(endpoints.departsChangeStatus, body));
