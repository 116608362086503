import {useEffect, useState} from "react";
import {AppButton, AppInput, Dropdown, TableHead, TableLoader} from "../../components";
import {ItemType} from "../../types";
import {AiOutlineMore} from "react-icons/ai";
import {BsSearch, BsTrash, BsUiChecks} from "react-icons/bs";
import {useForm} from "react-hook-form";
import {HasPermissions} from "../../helpers";
import {appPermissions} from "../../constants";
import {LuLayoutList} from "react-icons/lu";

type PropsType = {
    data: ItemType[]
    isLoading: boolean
    TableRow: any
    tableHead: any[]
    onItemClick?: (item: ItemType) => void
    onSelectItem?: (id: never) => void
    selectedItems?: string[] | number[]
    onMassDelete?: () => void
    isSelectionButtonLoading?: boolean
    hasMassAction?: boolean
    hasSearchInput?: boolean
}

const DataTable = (props: PropsType) => {
    const {
        data,
        isLoading,
        onItemClick,
        selectedItems = [],
        onSelectItem,
        onMassDelete,
        isSelectionButtonLoading,
        TableRow,
        hasSearchInput = true,
        hasMassAction = true,
        tableHead
    } = props
    const {register, watch} = useForm({mode: "all"});
    const [selectedTabItems, setSelectedTabItems] = useState<number[] | string[]>(selectedItems)
    const [stateData, setStateData] = useState<ItemType[]>(data)

    const massActionsMenus = [
        [
            {
                type: "custom",
                menuItem: {
                    icon: <BsTrash className="text-red-500"/>,
                    onClick: onMassDelete ? () => onMassDelete() : () => {
                    },
                    title: "Supprimer"
                },
                isHidden: !HasPermissions(appPermissions.directions, "deleter")
            },
        ],
        [
            {
                type: "custom",
                menuItem: {
                    icon: <BsUiChecks className="text-blue-500"/>,
                    onClick: () => setSelectedTabItems(data?.map(item => parseInt(item?.id?.toString() || ''))),
                    title: "Sélectionner Tout"
                },
            },
            {
                type: "custom",
                menuItem: {
                    icon: <LuLayoutList className="text-slate-500"/>,
                    onClick: () => setSelectedTabItems([]),
                    title: "Désélectionner Tout"
                },
            },
        ]
    ]

    useEffect(() => {
        setStateData(data)
    }, [data])

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if(data && name === "search"){
                const temp: ItemType[] = data?.filter((item:any)=>
                    item?.title?.toLowerCase()?.includes(value?.search?.toLowerCase()) ||
                    item?.description?.toLowerCase()?.includes(value?.search?.toLowerCase())
                )
                setStateData(temp)
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, data])

    return (
        <div>
            <div className="mt-4 grid md:grid-cols-4 grid-cols-1">
                <div>
                    {hasMassAction ? <Dropdown
                        title="Selections"
                        position="left"
                        menus={massActionsMenus}
                        type="custom"
                        titleComponent={
                            <AppButton
                                isButton={false}
                                icon={<AiOutlineMore/>}
                                label={selectedItems.length > 0 ? `SELECTIONS (${selectedItems.length})` : "SELECTIONS"}
                                bgColor="bg-cyan-500 hover:bg-cyan-700"
                                size="sm"
                                isLoading={isSelectionButtonLoading}
                            />
                        }
                    />: null}
                </div>
                <div className="col-span-2"/>
                <div>
                    {hasSearchInput ? <AppInput
                        {...register("search")}
                        rightIcon={<BsSearch/>}
                        name="search"
                    />: null}
                </div>
            </div>

            <table className="w-full appTable">
                <TableHead data={onSelectItem ? [{title: "#", className: "w-[50px]"}, ...tableHead] : tableHead}/>
                <tbody>
                {stateData && stateData?.length ? stateData.map((item, index) => (
                    <TableRow
                        isOdd={index % 2 === 0}
                        item={item}
                        key={index}
                        onItemClick={onItemClick}
                        selectedItems={selectedTabItems}
                        onSelectItem={onSelectItem}
                    />
                )) : null}
                </tbody>
            </table>

            <TableLoader isLoading={isLoading}/>
        </div>
    )
}

export default DataTable
