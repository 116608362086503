import React, { PropsWithChildren } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { BsChevronDoubleLeft } from 'react-icons/bs'

type PropsType = {
    isOpen: boolean,
    onClose: () => void,
    viewWidth?: string
}

const animationsShow = {
    from: {
        x: '300vw',
    },
    to: {
        x: 0,
    },
}


const DetailSideView = (props: PropsWithChildren<PropsType>) => {
    const { children, onClose, isOpen, viewWidth = 'w-[80%]' } = props
    const onOutClick = (event: any) => {
        event.preventDefault()
        if (event.target === event.currentTarget) {
            onClose()
        }
    }

    return (
        <div onClick={onOutClick}
             style={{backgroundColor: "rgba(0,0,0,0.5)"}}
             className='mt-[40px] w-full fixed top-0 right-0 flex flex-row justify-end z-10'>
            <AnimatePresence mode='sync'>
                {isOpen && (<motion.div
                    variants={animationsShow}
                    transition={{type: "tween"}}
                    initial='from'
                    animate='to'
                    exit='from'
                    className={`${viewWidth} relative flex flex-col  bg-gray-200 pl-10  pb-20 pr-4 pt-4 h-screen overflow-y-scroll transition-all duration-100 ease-in-out`}>


                    {children}

                </motion.div>)}
            </AnimatePresence>
        </div>
    )
}

export default DetailSideView
