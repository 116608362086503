import {FormatUrlParams, GetQueryKey} from "../../utils";
import {useMutation, useQuery} from "react-query";
import {http} from "../index";
import {cacheTime, endpoints} from "../../constants";
import {ChangeStatusBodyType, DefaultBodyType} from "../../types";

type OptionsType = {
    id?: string | number
    direction_id?: string | number
    status?: string
}


export const UseGetSubStations = (options?: OptionsType) =>
    useQuery(GetQueryKey("substations", options), async () => http.get(`${endpoints.substations}${FormatUrlParams(options)}`), {staleTime: cacheTime.long});

export const UseAddSubStation = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.substations, body));


export const UseUpdateSubStation = () =>
    useMutation(async (body: DefaultBodyType) => http.post(`${endpoints.substations}/${body.id}`, body));

export const UseImportSubStations = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.substationsImport, body, { headers: {'content-type': 'multipart/form-data'}}));

export const UseChangeSubStationsStatus = () =>
    useMutation(async (body: ChangeStatusBodyType) => http.post(endpoints.substationsChangeStatus, body));
