import {useMutation, useQuery} from "react-query";
import {http} from "../index";
import {endpoints, cacheTime} from "../../constants";
import {FormatUrlParams, GetQueryKey} from "../../utils";

interface LoginBodyType {
    username: string
    password: string
}

type QueryParams = {
    id?: string | number
    role_id?: string | number
    status?: string | number
    print?: "true"
}

type ChangePasswordBodyType = {
    id: string | number
    actual_password?: string | number
    confirm_new_password: string
    new_password: string
};



export const UseGetUsers = (options?: QueryParams) =>
    useQuery(
        GetQueryKey("users", options),
        async () => http.get(`${endpoints.users}${FormatUrlParams(options)}`),
        {staleTime: cacheTime.long}
    );


export const UseLogin = () =>
    useMutation(async (body: LoginBodyType) => http.post(`${endpoints.login}`, body))

export const UseLogout = () =>
    useMutation(async () => http.delete(`${endpoints.logout}`))

export const UseGetUserPermissions = (id: string | number) =>
    useQuery(["userAccess", id], async () => http.get(`${endpoints.userAccesses}/${id}`), {staleTime: cacheTime.long});

export const UseGetUserLoginHistories = (id: string | number) =>
    useQuery(["loginHistories", id], async () => http.get(`${endpoints.userLoginHistories}/${id}`), {staleTime: cacheTime.long});

export const UseChangeFirstPassword = () =>
    useMutation(async (body: ChangePasswordBodyType) => http.post(`${endpoints.userChangeFirstPassword}/${body.id}`, body));

