import React, {createContext, useState} from "react";
import {AppStorage} from "../utils";
import {localStorageKeys} from "../constants";
import {AuthDataType} from "../types";

type Props = {
    currentUser: AuthDataType
    setCurrentUser: Function
}

export const UserContext = createContext<Props>({
    currentUser: {
        token: null,
        user: null,
        permissions: null,
    },
    setCurrentUser: () => {}
})

export const getCurrentUser = () => {
    const token = AppStorage.getItem(localStorageKeys.token)
    let user = AppStorage.getItem(localStorageKeys.user)
    user = user ? JSON.parse(user) : null
    let permissions = AppStorage.getItem(localStorageKeys.permissions)
    permissions = permissions ? JSON.parse(permissions) : null
    return {token, user, permissions}
}

const UserContextProvider: React.FC<any> = (props: any) => {
    const [currentUser, setCurrentUser] = useState<AuthDataType>(getCurrentUser())

    const value: Props = {currentUser, setCurrentUser}

    return (
        <UserContext.Provider value={value}>
            {props.children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;
