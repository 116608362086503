import {ReactNode} from "react";

type PropsType = {
    data: {title?: string | ReactNode, className?: string}[]
    className?: string
}

const TableHead = (props: PropsType) => {
    const {data, className} = props
    return (
        <thead className="">
        <tr className={`${className} text-[14px] bg-gray-500 text-white`}>
            {data.map((item, index) => <th key={index} className={`appTh ${item?.className}`}>{item.title}</th>)}
        </tr>
        </thead>
    )
}

export default TableHead
