import {ReactNode, useState} from "react";
import {BlockingModal} from "../index";

type PropsType = {
    icon: ReactNode
    title: string | ReactNode,
    subTitle?: string
    bg?: string
    onClick: any
    subMenus?: any[]
}

const Item = ({icon, title, subTitle, bg, onClick}: PropsType) => {
    return (
        <div
            onClick={() => onClick()}
            className="h-[100px] flex flex-row justify-between items-center shadow-lg w-full bg-white cursor-pointer hover:shadow-2xl hover:-translate-y-1 transition-all ease-in duration-200 px-4">
            <div>
                <div className={`${bg} p-4 rounded-full text-white text-3xl`}>
                    {icon}
                </div>
            </div>
            <div className="text-end">
                <div className="font-bold text-lg text-sky-500">{title}</div>
                {subTitle && <div className="text-gray-500 text-sm">{subTitle}</div>}
            </div>
        </div>
    )
}

const HomeMenuItem = (props: PropsType) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const {icon, title, onClick, subTitle, bg = "bg-red-600", subMenus} = props
    return (
        <>
            <Item
                icon={icon}
                title={title}
                onClick={subMenus ? () => setIsOpen(true) : () => onClick()}
                subTitle={subTitle}
                bg={bg}
            />
            {
                subMenus && subMenus.length ? (
                        <BlockingModal
                            isOpen={isOpen}
                            onClose={() => setIsOpen(false)}
                            closeOnOutsideClick
                            content={
                                <div className="w-[600px] grid grid-cols-2 gap-8 ">
                                    <div className="col-span-2 text-center font-bold text-2xl">{title}</div>

                                    {subMenus.map((subMenu, index: number) => (
                                        <Item
                                            key={index}
                                            icon={subMenu.icon}
                                            title={subMenu.title}
                                            onClick={subMenu.onClick}
                                            bg={bg}
                                        />
                                    ))}
                                </div>
                            }
                        />
                ) : null
            }

        </>
    )
}

export default HomeMenuItem
