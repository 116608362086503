import React, {useContext} from "react";
import {Navigate, Outlet} from "react-router-dom";
import {UserContext} from "../contexts/UserContext";

export default function LoggedRoute() {
    const {currentUser} = useContext(UserContext);
    if (currentUser.token && currentUser.permissions && currentUser.user) {
        return <Navigate to="/" replace/>;
    }
    return <Outlet/>;
}
