import moment from "moment";
import {AppStorage} from "./index";
import {localStorageKeys, perturbationsTypesData, routes} from "../constants";
import {AuthDataType} from "../types";
import {AppToast} from "../helpers";

export {default as AppStorage} from "./AppLocalStorage"
export {decryptData, encryptData} from "./AppEncrypt"

export const formatDate = (date?: string | Date, format?: "fr" | "en") => {
    const f = format ? format : "fr"
    if (!date) return ""
    return moment(date).format(f === "en" ? 'YYYY-MM-DD' : 'DD-MM-YYYY')
}

export const formatDateTime = (date?: string | Date, format?: "fr" | "en") => {
    const f = format ? format : "fr"
    if (!date) return ""
    return moment(date, f === 'en'  ? "DD-MM-YYYY HH:mm" : "").format(f === "en" ? 'YYYY-MM-DD HH:mm' : 'DD-MM-YYYY HH:mm')
}

export const reverseDate = (date: string | Date, f?: "fr" | "en") => {
    const format = f ? f : "en"
    if (!date) return ""
    if (format === "fr") {
        const [year, month, day] = date.toString().split('-');
        return [day, month, year].join('-');
    }
    const [day, month, year] = date.toString().split('-');
    return [year, month, day].join('-');
}

export const getDateDiff = (start: Date, end: Date) => {
    const diffInMs = Math.abs(end.getTime() - start.getTime());
    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diffInMs / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diffInMs / (1000 * 60)) % 60);
    const seconds = Math.floor((diffInMs / 1000) % 60);
    return { days, hours, minutes, seconds };
}

export const getDateDiffWithMoment = (start: Date, end: Date) => {
    const diff = moment.duration(moment(end).diff(moment(start)));
    const days = Math.floor(diff.asDays());
    const hours = Math.floor(diff.hours());
    const minutes = Math.floor(diff.minutes());
    const seconds = Math.floor(diff.seconds());
    return { days, hours, minutes, seconds };
}

export const getMinuteDiff = (start: Date | string, end: Date | string) => {
    const diff = moment.duration(moment(end).diff(moment(start)));
    const days = Math.floor(diff.asDays());
    const hours = Math.floor(diff.hours());
    return Math.floor(diff.minutes()) + (hours*60) + (days*24*60);
}

export const formatNumber = (num:number, decimals = 2, dec_point = ".", thousands_sep = " ")  => {
    // Strip all characters but numerical ones.
    const number = (num + '').replace(/[^0-9+\-Ee.]/g, '');
    let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s : string | string[]= '',
        toFixedFix = function (n:number, prec: number) {
            let k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export const setAuthData = (data: AuthDataType) => {
    AppStorage.setItem(localStorageKeys.token, data.token || "")
    AppStorage.setItem(localStorageKeys.user, JSON.stringify(data.user))
    AppStorage.setItem(localStorageKeys.permissions, JSON.stringify(data.permissions))
}

export const removeAuthData = () => {
    AppStorage.removeItem(localStorageKeys.token)
    AppStorage.removeItem(localStorageKeys.user)
    AppStorage.removeItem(localStorageKeys.permissions)
    AppStorage.clearAll()
}

export const FormatUrlParams = (params?: {[key: string]: any}) => {
    if(!params) return ""
    let url = ""
    Object.keys(params).map((item, index) => {
        if(index === 0) url = `?${item}=${params[item] || ""}`
        else url += `&${item}=${params[item] || ""}`
        return item
    })
    return url
}

export const GetQueryKey = (key: string, params?: {[key: string]: any}) => {
    if(!params) return [key]
    const options = Object.values(params).map(item => item)
    return [key, ...options]
}

export const GetFilterDate = (key: string) => {
    return  AppStorage.getItem(key) || moment().format("DD-MM-YYYY")
}

export const SetFilterDate = (key: string, d: string) => {
    return AppStorage.setItem(key, d)
}


export const checkPerturbationType = (type?: any) => {
    if(perturbationsTypesData.some((item) => item.id === type)) {
        return true
    }
    AppToast.error("Type de perturbations non reconnu !!")
    window.location.href = routes.home
}
