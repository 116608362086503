import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {
    AppButton,
    AppCheckBox,
    AppInput,
    BlockingModal, DetailSideView,
    Dropdown, ItemStatus,
    PageTitleMenu,
    TableHead,
    TableLoader
} from "../../components";
import {appPermissions, routes} from "../../constants";
import {BsPlusCircle, BsSearch, BsTrash, BsUiChecks} from "react-icons/bs";
import {AppToast, HasPermissions} from "../../helpers";
import {FaFileCsv, FaFileImport} from "react-icons/fa6";
import {DirectionDetail, DirectionForm} from "../index";
import {FormActionType, ItemType, RowPropsType} from "../../types";
import {UseChangeDirectionsStatus, UseGetDirections} from "../../services";
import {AiOutlineMore} from "react-icons/ai";
import {useModalConfirm} from "../../contexts";
import {useForm} from "react-hook-form";
import {LuLayoutList} from "react-icons/lu";

const TableRow = (props: RowPropsType) => {
    const {isOdd, item, onItemClick, selectedItems, onSelectItem} = props

    const checkIndex = (data: any, item: any) => {
        return data.indexOf(item)
    }


    return (
        <tr
            className={`cursor-pointer appTrHover text-[13px] ${isOdd ? 'appTrOdd' : 'appTr'}`}>
            <td className="appTd font-bold text-blue-500 border-b-4">
                <AppCheckBox
                    id={item.id}
                    isChecked={checkIndex(selectedItems, item.id) > -1}
                    onChange={onSelectItem}
                />
            </td>
            <td onClick={onItemClick ? () => onItemClick(item) : ()=>console.log("")}
                className="appTd font-semibold text-blue-500">{item.title}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : ()=>console.log("")}
                className="appTd">{item.address}</td>
            <td className="appTd text-center"><ItemStatus item={item}/></td>
        </tr>
    )
}


const DirectionsList = () => {
    const navigate = useNavigate()
    const {state} = useLocation()
    const {modalConfirm} = useModalConfirm()
    const {register, watch} = useForm({mode: "all"});

    const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false)
    const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false)
    const [selectedFormAction, setSelectedFormAction] = useState<FormActionType>("add")
    const [selectedItem, setSelectedItem] = useState<ItemType>()
    const [selectedItems, setSelectedItems] = useState<number[] | string[]>([])
    const [check, setCheck] = useState<boolean>(false)
    const [directions, setDirections] = useState<ItemType[]>([])

    const {isLoading: isDataLoading, data: itemData, refetch: reGetData} = UseGetDirections()
    const {isLoading: isChangingStatus, mutate: changeStatus, data: changeStatusResult} = UseChangeDirectionsStatus()

    const headMenus = [
        {
            title: "Ajouter une Direction",
            onClick: () => {
                setSelectedFormAction("add")
                setIsFormModalOpen(true)
            },
            permissions: HasPermissions(appPermissions.directions, "creator"),
            icon: <BsPlusCircle/>,
            className: 'bg-green-600 hover:bg-green-800 text-white',
        },
        {
            title: 'Importer fichier',
            onClick: () => console.log(""),
            permissions: HasPermissions(appPermissions.directions, "exporter"),
            icon: <FaFileImport/>,
        },
        {
            title: 'Exporter sous CSV',
            onClick: () => console.log(""),
            permissions: HasPermissions(appPermissions.directions, "exporter"),
            icon: <FaFileCsv/>,
        },
    ]
    const massActionsMenus = [
        [
            {
                type: "custom",
                menuItem: {
                    icon: <BsTrash className="text-red-500"/>,
                    onClick: () => onMassDelete(),
                    title: "Supprimer"
                },
                isHidden: !HasPermissions(appPermissions.directions, "deleter")
            },
        ],
        [
            {
                type: "custom",
                menuItem: {
                    icon: <BsUiChecks className="text-blue-500"/>,
                    onClick: () => setSelectedItems(directions?.map(item => parseInt(item?.id?.toString() || ''))),
                    title: "Sélectionner Tout"
                },
            },
            {
                type: "custom",
                menuItem: {
                    icon: <LuLayoutList className="text-slate-500"/>,
                    onClick: () => setSelectedItems([]),
                    title: "Désélectionner Tout"
                },
            },
        ]
    ]
    const tableHead = [
        {title: "#", className: "w-[50px]"},
        {title: "Désignations"},
        {title: "Adresses"},
        {title: "Statut", className: "w-[100px]"},
    ]

    useEffect(() => {
        if(itemData) setDirections(itemData?.data)
    }, [itemData])

    useEffect(() => {
        if(state?.refetch) reGetData()
    }, [state])

    useEffect(() => {
        if(changeStatusResult){
            changeStatusResult.error ? AppToast.error(changeStatusResult?.message) : AppToast.success("Action effectuée avec succès.")
            reGetData()
            setSelectedItems([])
            return
        }
    }, [changeStatusResult])

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if(itemData && itemData.data && name === "search"){
                const temp: ItemType[] = itemData?.data?.filter((item:any)=>
                    item?.title?.toLowerCase()?.includes(value?.search?.toLowerCase()) ||
                    item?.address?.toLowerCase()?.includes(value?.search?.toLowerCase())
                )
                setDirections(temp)
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, itemData])

    const onMassDelete = async () => {
        if (await modalConfirm()) {
            changeStatus({
                ids: selectedItems,
                status: "-1"
            })
        }
    }

    const onValidateForm = async (newItem?: ItemType) => {
        setIsFormModalOpen(false)
        await reGetData()
        if(newItem) {
            setSelectedItem(newItem)
        }
    }

    const onSelectItem = (id: never) => {
        let temp = selectedItems
        const index = temp.indexOf(id);
        if (index > -1) temp.splice(index, 1);
        else temp.push(id);
        setSelectedItems(temp)
        setCheck(!check)
    }

    const onItemClick = (item: ItemType) => {
        setSelectedItem(item)
        setIsDetailOpen(true)
    }

    const onEditClick = (item: any) => {
        setSelectedFormAction("update")
        setSelectedItem(item)
        setIsFormModalOpen(true)
    }

    return (
        <div>
            <PageTitleMenu
                title="Toutes les Directions"
                onBackIconClick={() => navigate(routes.home)}
                rightMenus={headMenus}
            />

            <div className="mt-4 grid md:grid-cols-4 grid-cols-1">
                <div>
                    <Dropdown
                        title="Selections"
                        position="left"
                        menus={massActionsMenus}
                        type="custom"
                        titleComponent={
                            <AppButton
                                isButton={false}
                                icon={<AiOutlineMore/>}
                                label={selectedItems.length > 0 ? `SELECTIONS (${selectedItems.length})` : "SELECTIONS"}
                                bgColor="bg-cyan-500 hover:bg-cyan-700"
                                size="sm"
                                isLoading={isChangingStatus}
                            />
                        }
                    />
                </div>
                <div className="col-span-2"/>
                <div>
                    <AppInput
                        {...register("search")}
                        rightIcon={<BsSearch/>}
                        name="search"
                    />
                </div>
            </div>

            <table className="w-full appTable">
                <TableHead data={tableHead}/>
                <tbody>
                {directions && directions?.length ? directions.map((item, index) => (
                    <TableRow
                        isOdd={index % 2 === 0}
                        item={item}
                        key={index}
                        onItemClick={onItemClick}
                        selectedItems={selectedItems}
                        onSelectItem={onSelectItem}
                    />
                )) : null}
                </tbody>
            </table>

            <TableLoader isLoading={isDataLoading}/>

            <BlockingModal
                isOpen={isFormModalOpen}
                onClose={() => setIsFormModalOpen(false)}
                content={
                    <div className='w-[550px]'>
                        <DirectionForm
                            onValidate={onValidateForm}
                            item={selectedItem}
                            action={selectedFormAction}
                            isModal
                        />
                    </div>
                }
            />

            <DetailSideView
                isOpen={isDetailOpen}
                onClose={() => {
                    setIsDetailOpen(false)
                    setSelectedFormAction("add")
                }}
                children={
                    <DirectionDetail
                        item={selectedItem}
                        onDelete={async () => {
                            await reGetData()
                            setIsDetailOpen(false)
                        }}
                        onEditClick={onEditClick}
                        isModal
                    />
                }
                viewWidth="w-[50%]"
            />
        </div>
    )
}

export default DirectionsList
