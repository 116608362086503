import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";

export default function ProtectedRoute() {
    const {currentUser} = useContext(UserContext);
    if (!currentUser.token || !currentUser.permissions || !currentUser.user) {
        return <Navigate to="/login" replace/>;
    }
    return <Outlet />;
}
