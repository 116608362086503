import {ReactNode} from "react";

type PropsTYpe = {
    title: string | ReactNode
    className?: string
}

const Badge = (props: PropsTYpe) => {
    const {title, className} = props
    return (
        <span className={`px-2 py-1 rounded-xl text-xs text-white font-bold ${className}`}>{title}</span>
    )
}

export default Badge
