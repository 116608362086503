import {FormActionType, ItemType} from "../../types"
import {FieldValues, useForm} from "react-hook-form";
import {AppButton, AppInput, PageTitleMenu} from "../../components";
import {UseAddDirection, UseUpdateDirection} from "../../services";
import {useEffect} from "react";
import {AppToast} from "../../helpers";
import {useLocation, useNavigate} from "react-router-dom";
import {routes} from "../../constants";

type PropsType = {
    action?: FormActionType
    onValidate?: (newItem?: ItemType) => void
    item?: ItemType
    isModal?: boolean
}

const DirectionForm = (props: PropsType) => {
    const {action, onValidate, isModal} = props
    const isUpdate = action === 'update'
    const navigate = useNavigate()
    const {state} = useLocation()
    const item = props?.item || state?.item

    const {isLoading: isAdding, mutate: addItem, data: addResult} = UseAddDirection()
    const {isLoading: isUpdating, mutate: updateItem, data: updateResult} = UseUpdateDirection()

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        mode: 'onSubmit', defaultValues: {
            title: isUpdate && item ? item?.title : '',
            address: isUpdate && item ? item?.address : '',
        },
    })

    useEffect(() => {
        if (addResult) {
            if (addResult.error) {
                AppToast.error(addResult.message)
                return
            } else {
                AppToast.success("Direction enregistrée avec succès !")
                if (onValidate) onValidate()
                return
            }
        }
    }, [addResult])

    useEffect(() => {
        if (updateResult) {
            if (updateResult.error) {
                AppToast.error(updateResult.message)
                return
            } else {
                AppToast.success("Direction modifiée avec succès !")
                if (onValidate) onValidate({...item, ...updateResult?.data})
                if(!isModal) navigate(routes.directionsList, {state: {refetch: true}})
                return
            }
        }
    }, [updateResult])

    const onSubmit = (data: FieldValues) => {
        const body = {
            title: data.title,
            address: data.address,
        }
        if (isUpdate) updateItem({...body, id: item?.id})
        else addItem(body)
    }

    return (
        <div>
            <PageTitleMenu
                title={<span>{isUpdate ? 'Modifier' : 'Ajouter'} une  Direction</span>}
                hasBackIcon={!isModal}
            />

            <div className={`grid ${isModal ? 'grid-cols-1' : 'grid-cols-3'}`}>
                <div/>
                <form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
                    <AppInput
                        {...register('title', {required: 'Entrez la désignation'})}
                        type='text'
                        name='title'
                        label='Désignation'
                        error={!!(errors && errors['title'])}
                        errorMessage={errors['title']?.message?.toString()}
                        required
                    />

                    <AppInput
                        {...register('address')}
                        type='text'
                        name='address'
                        label='Adresse'
                        isTextarea
                    />

                    <div className='text-center mt-5'>
                        <AppButton
                            type='submit'
                            label='Valider'
                            bgColor='bg-cyan-600 hover:bg-cyan-900'
                            className="w-1/2"
                            isLoading={isAdding || isUpdating}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DirectionForm
