import {ReactNode, useState} from "react";
import {useNavigate} from "react-router-dom";
import {GoHome} from "react-icons/go";
import {
    BsBarChartFill,
    BsChevronDown,
    BsChevronRight,
    BsFillCircleFill,
    BsFillGearFill, BsGrid3X3Gap, BsLayoutWtf, BsPeopleFill,
    BsWrenchAdjustable
} from "react-icons/bs";
import {appPermissions, perturbationsTypes, routes} from "../../constants/";
import {HasPermissions} from "../../helpers";
import {BiSolidCalendarExclamation} from "react-icons/bi";
import {TbBuildingBroadcastTower} from "react-icons/tb";

type PropsType = {
    isOpen?: boolean
    toggleSideBar: () => void
}

type MenuItemPropsType = {
    index: number
    title: string
    icon: ReactNode
    onClick: () => void
    permission: string
    subMenus?: any
    subMenuOpens?: string[]
}

const MenuItem = (props: MenuItemPropsType) => {
    const {title, icon, onClick, subMenus, index, subMenuOpens} = props
    return (
        <div
            onClick={onClick}
            className={`flex flex-row items-center justify-between py-2 px-2 rounded text-white bg-sky-600 mb-2  hover:bg-sky-950 hover:shadow-xl hover:-translate-y-0.5 transition-all duration-500 ease-in-out`}
        >
            <div className="flex flex-row items-center">
                {icon}
                <div className="ml-3">{title}</div>
            </div>

            {subMenus && <span>
                {subMenuOpens && subMenuOpens[index] === "true" ? <BsChevronDown/> : <BsChevronRight/>}
            </span>}
        </div>
    )
}

const SideBar = (props: PropsType) => {
    const navigate = useNavigate()
    const menus: MenuItemPropsType[] = [
        {
            index: 0,
            title: "Accueil",
            icon: <GoHome/>,
            onClick: () => onMenuItemClick("/", 0),
            permission: appPermissions.none
        },
        {
            index: 1,
            title: "Installations",
            icon: <TbBuildingBroadcastTower/>,
            onClick: () => onMenuItemClick(routes.profile, 1),
            permission: appPermissions.none,
            subMenus: [
                {
                    title: "Directions",
                    onClick: () => onMenuItemClick(routes.directionsList),
                    permission: appPermissions.directions,
                },
                {
                    title: "Sous stations",
                    onClick: () => onMenuItemClick(routes.substationsList),
                    permission: appPermissions.substations,
                },
                {
                    title: "Feeders",
                    onClick: () => onMenuItemClick(routes.feedersList),
                    permission: appPermissions.feeders,
                },
                {
                    title: "Cabines",
                    onClick: () => onMenuItemClick(routes.cabinesList),
                    permission: appPermissions.cabines,
                }
            ]
        },
        {
            index: 2,
            title: "Perturbations",
            icon: <BiSolidCalendarExclamation/>,
            onClick: () => onMenuItemClick("/", 2),
            permission: appPermissions.none,
            subMenus: [
                {
                    title: "Pert. Sous stations",
                    onClick: () => onMenuItemClick(`${routes.perturbationsList}/${perturbationsTypes.substation}`),
                    permission: appPermissions.perturbations,
                },
                {
                    title: "Pert. Feeders",
                    onClick: () => onMenuItemClick(`${routes.perturbationsList}/${perturbationsTypes.feeder}`),
                    permission: appPermissions.perturbations,
                },
                {
                    title: "Pert. Cabines",
                    onClick: () => onMenuItemClick(`${routes.perturbationsList}/${perturbationsTypes.cabine}`),
                    permission: appPermissions.perturbations,
                },
                {
                    title: "Pert. Départs",
                    onClick: () => onMenuItemClick(`${routes.perturbationsList}/${perturbationsTypes.depart}`),
                    permission: appPermissions.perturbations,
                }
            ]
        },
        {
            index: 3,
            title: "Relevés Charges",
            icon: <BsGrid3X3Gap/>,
            onClick: () => onMenuItemClick("/", 3),
            permission: appPermissions.none,
            subMenus: [
                {
                    title: "Relevés",
                    onClick: () => onMenuItemClick(routes.releveNew),
                    permission: appPermissions.none,
                },
                {
                    title: "Pointes",
                    onClick: () => onMenuItemClick(routes.profile),
                    permission: appPermissions.none,
                },
            ]
        },
        {
            index: 4,
            title: "Abonnés",
            icon: <BsPeopleFill/>,
            onClick: () => onMenuItemClick("/", 4),
            permission: appPermissions.none
        },
        {
            index: 5,
            title: "M.O Manoeuvres",
            icon: <BsLayoutWtf/>,
            onClick: () => onMenuItemClick("/", 5),
            permission: appPermissions.none
        },
        {
            index: 6,
            title: "M.S Equipements",
            icon: <BsWrenchAdjustable/>,
            onClick: () => onMenuItemClick("/", 6),
            permission: appPermissions.none
        },
        {
            index: 7,
            title: "Statistiques",
            icon: <BsBarChartFill/>,
            onClick: () => onMenuItemClick("/", 7),
            permission: appPermissions.none,
            subMenus: [
                {
                    title: "Stat. Sous stations",
                    onClick: () => onMenuItemClick(routes.profile),
                    permission: appPermissions.none,
                },
                {
                    title: "Stat. Feeders",
                    onClick: () => onMenuItemClick(routes.profile),
                    permission: appPermissions.none,
                },
                {
                    title: "Bilan Energie",
                    onClick: () => onMenuItemClick(routes.profile),
                    permission: appPermissions.none,
                },
            ]
        },
        {
            index: 8,
            title: "Paramètres",
            icon: <BsFillGearFill/>,
            onClick: () => onMenuItemClick("/", 8),
            permission: appPermissions.none,
            subMenus: [
                {
                    title: "Utilisateurs",
                    onClick: () => onMenuItemClick(routes.profile),
                    permission: appPermissions.users,
                },
                {
                    title: "Catégories de Feeders",
                    onClick: () => onMenuItemClick(routes.feederCategoriesList),
                    permission: appPermissions.feederCategories,
                },
                {
                    title: "CVS",
                    onClick: () => onMenuItemClick(routes.cvsList),
                    permission: appPermissions.cvs,
                },
                {
                    title: "Causes de Pertubat.",
                    onClick: () => onMenuItemClick(routes.perturbationCausesList),
                    permission: appPermissions.perturbationCauses,
                },
                {
                    title: "Natures de Pertubat.",
                    onClick: () => onMenuItemClick(routes.perturbationNaturesList),
                    permission: appPermissions.perturbationsNatures,
                },
            ]
        },
    ]


    const {isOpen = true, toggleSideBar} = props
    const [isSubOpen, setIsSubOpen] = useState<string[]>(Array.from("false".repeat(menus.length)))

    const onMenuItemClick = (route: string, index: number = 0) => {
        if (menus[index]?.subMenus) {
            setIsSubOpen(prev => {
                let temp = [...prev]
                temp[index] = temp[index] === "true" ? "false" : "true"
                return temp
            })
        } else {
            navigate(route)
            toggleSideBar()
        }
    }

    const onOutClick = (event: any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            toggleSideBar()
        }
    };


    return (
        <div onClick={onOutClick}
             className={`${isOpen ? "w-full" : "-left-64"} z-40 h-full  cursor-pointer fixed absolute top-12 bg-gray-500/20 transition-all duration-500 ease-in-out`}>
            <div
                className="w-60 bg-sky-800 h-full fixed transition-all overflow-y-scroll duration-500 ease-in-out px-5 pt-5 pb-20">
                {menus.map((item: MenuItemPropsType, index: number) => HasPermissions(item.permission) ? (
                    <div key={index}>
                        <MenuItem
                            index={item.index}
                            permission={item.permission}
                            onClick={item.onClick}
                            title={item.title}
                            icon={item.icon}
                            subMenus={item.subMenus}
                            subMenuOpens={isSubOpen}
                        />
                        {item.subMenus && isSubOpen[index] === "true" ?
                            <div className="ml-8">
                                {item.subMenus.map((subItem: any, index2: number) => HasPermissions(subItem.permission) ? (
                                    <div onClick={subItem.onClick} key={index2}
                                         className="mb-2 text-white flex flex-row items-center">
                                        <BsFillCircleFill className="inline text-[10px] mr-3"/>
                                        <span
                                            className="hover:underline transition-all duration-200 ease-in-out">{subItem.title}</span>
                                    </div>
                                ) : null)}
                            </div> : null}
                    </div>
                ) : null)}
            </div>
        </div>
    )
}

export default SideBar
