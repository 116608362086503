import {FormActionType, ItemType} from "../../types"
import {Controller, FieldValues, useForm} from "react-hook-form";
import {AppButton, AppInput, AppSelect, PageTitleMenu} from "../../components";
import {UseAddFeeder, UseGetFeederCategories, UseGetSubStations, UseUpdateFeeder} from "../../services";
import {useEffect} from "react";
import {AppToast} from "../../helpers";
import {routes} from "../../constants";
import {useLocation, useNavigate} from "react-router-dom";

type PropsType = {
    action?: FormActionType
    onValidate?: (newItem?: ItemType) => void
    item?: ItemType
    isModal?: boolean
}

const FeederForm = (props: PropsType) => {
    const {action, onValidate, isModal} = props
    const isUpdate = action === 'update'
    const {state} = useLocation()
    const navigate = useNavigate()
    const item = props?.item || state?.item

    const {isLoading: isAdding, mutate: addItem, data: addResult} = UseAddFeeder()
    const {isLoading: isUpdating, mutate: updateItem, data: updateResult} = UseUpdateFeeder()
    const {isLoading: isGettingSubstations, data: substations} = UseGetSubStations()
    const {isLoading: isGettingCategories, data: categories} = UseGetFeederCategories()

    const {
        control,
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        mode: 'onSubmit', defaultValues: {
            title: isUpdate && item ? item?.title : '',
            description: isUpdate && item ? item?.description : '',
            substation: isUpdate && item ? item?.sub_station_id : '',
            category: isUpdate && item ? item?.category_id : '',
            smva: isUpdate && item ? item?.smva : '',
            section: isUpdate && item ? item?.section : '',
        },
    })

    useEffect(() => {
        if (addResult) {
            if (addResult.error) {
                AppToast.error(addResult.message)
                return
            } else {
                AppToast.success("Feeder enregistré avec succès !")
                if (onValidate) onValidate()
                if (!isModal) navigate(routes.feedersList, {state: {refetch: true}})
                return
            }
        }
    }, [addResult])

    useEffect(() => {
        if (updateResult) {
            if (updateResult.error) {
                AppToast.error(updateResult.message)
                return
            } else {
                AppToast.success("Feeder modifié avec succès !")
                if (onValidate) onValidate({...item, ...updateResult?.data})
                if (!isModal) navigate(routes.feedersList, {state: {refetch: true}})
                return
            }
        }
    }, [updateResult])

    const onSubmit = (data: FieldValues) => {
        const body = {
            title: data.title,
            description: data.description,
            section: data.section,
            smva: data.smva,
            sub_station_id: data.substation.id,
            category_id: data.category.id,
        }
        if (isUpdate) updateItem({...body, id: item?.id})
        else addItem(body)
    }

    return (
        <div>
            <PageTitleMenu
                title={<span>{isUpdate ? 'Modifier' : 'Ajouter'} un  Feeder</span>}
                hasBackIcon={!isModal}
            />
            <div className={`grid ${isModal ? 'grid-cols-1' : 'grid-cols-3'}`}>
                <div/>
                <form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
                    <AppInput
                        {...register('title', {required: 'Entrez la désignation'})}
                        type='text'
                        name='title'
                        label='Désignation'
                        error={!!(errors && errors['title'])}
                        errorMessage={errors['title']?.message?.toString()}
                        required
                    />

                    <Controller
                        control={control}
                        name="substation"
                        rules={{
                            required: {value: true, message: "Selectionnez une sous stattion"},
                        }}
                        render={({field}) => (
                            <AppSelect
                                {...field}
                                label="Sous Station"
                                name="substation"
                                required
                                text="title"
                                value="id"
                                selectedValue={isUpdate ? item?.substation_title : ""}
                                isLoading={isGettingSubstations}
                                data={substations?.data}
                                error={!!(errors && errors["substation"])}
                                errorMessage={errors["substation"]?.message?.toString()}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="category"
                        rules={{
                            required: {value: true, message: "Selectionnez une catégorie"},
                        }}
                        render={({field}) => (
                            <AppSelect
                                {...field}
                                label="Catégorie"
                                name="category"
                                required
                                text="title"
                                value="id"
                                selectedValue={isUpdate ? item?.category_title : ""}
                                isLoading={isGettingCategories}
                                data={categories?.data}
                                error={!!(errors && errors["category"])}
                                errorMessage={errors["category"]?.message?.toString()}
                            />
                        )}
                    />

                    <AppInput
                        {...register('section', {required: 'Entrez la section'})}
                        type='text'
                        name='section'
                        label='Section'
                        error={!!(errors && errors['section'])}
                        errorMessage={errors['section']?.message?.toString()}
                        required
                    />

                    <AppInput
                        {...register('smva', {required: "Entrez l'intensité nominale"})}
                        type='text'
                        name='smva'
                        label='Intensité nominale'
                        error={!!(errors && errors['smva'])}
                        errorMessage={errors['smva']?.message?.toString()}
                        required
                    />


                    <AppInput
                        {...register('description')}
                        type='text'
                        name='description'
                        label='Commentaires'
                        isTextarea
                    />

                    <div className='text-center mt-5'>
                        <AppButton
                            type='submit'
                            label='Valider'
                            bgColor='bg-cyan-600 hover:bg-cyan-900'
                            className="w-1/2"
                            isLoading={isAdding || isUpdating}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default FeederForm
