export {default as endpoints} from "./endpoints"
export {default as routes} from "./routes"
export {default as appPermissions} from "./appPermissions"

export const localStorageKeys = {
    token: "283HR4fwOiUeoHdiJSleiaOJKdbsijaeuslshdI6I",
    user: "iYeoanEmdkj1038DUJEJ3JJLKSOWhsueXPSjs",
    permissions: "9skjhuGEtl39dOIJI93hsonmspkjrzjs",
}

export const cacheTime = {
    long: 9990000,
    medium: 60000,
    short: 1000,
}

export const perturbationsTypes = {
    substation: "SST",
    feeder: "FDR",
    cabine: "CAB",
    depart: "DEP",
}

export const perturbationsKeys:{[key: string]: string} = {
    SST: "Sous station",
    FDR: "Feeder",
    CAB: "Cabine",
    DEP: "Depart",
}

export const perturbationsTypesData = [
    {id: "SST", title: "Sous station"},
    {id: "FDR", title: "Feeder"},
    {id: "CAB", title: "Cabine"},
    {id: "DEP", title: "Depart"},
]

export const statesData = [
    {id: "Bon", title: "Bon"},
    {id: "Assez bon", title: "Assez bon"},
    {id: "Mauvais", title: "Mauvais"},
]

export const cabinesTypeData = [
    {id: "Compact", title: "Compact"},
    {id: "Macon", title: "Macon"},
    {id: "Ciel ouvert", title: "Ciel ouvert"},
]

export const cabinesRegomeData = [
    {id: "Délestage", title: "Délestage"},
    {id: "Sans-Délestage", title: "Sans-Délestage"},
]

export const communesData = [
    {id: "Bandalungwa", title: "Bandalungwa"},
    {id: "Barumbu", title: "Barumbu"},
    {id: "Bumbu", title: "Bumbu"},
    {id: "Gombe", title: "Gombe"},
    {id: "Kalamu", title: "Kalamu"},
    {id: "Kasa-Vubu", title: "Kasa-Vubu"},
    {id: "Kimbanseke", title: "Kimbanseke"},
    {id: "Kinshasa", title: "Kinshasa"},
    {id: "Kintambo", title: "Kintambo"},
    {id: "Kisenso", title: "Kisenso"},
    {id: "Lemba", title: "Lemba"},
    {id: "Limete", title: "Limete"},
    {id: "Lingwala", title: "Lingwala"},
    {id: "Makala", title: "Makala"},
    {id: "Maluku", title: "Maluku"},
    {id: "Masina", title: "Masina"},
    {id: "Matete", title: "Matete"},
    {id: "Mont-Ngafula", title: "Mont-Ngafula"},
    {id: "Ndjili", title: "Ndjili"},
    {id: "Ngaba", title: "Ngaba"},
    {id: "Ngaliema", title: "Ngaliema"},
    {id: "Ngiri-Ngiri", title: "Ngiri-Ngiri"},
    {id: "Nsele", title: "Nsele"},
    {id: "Selembao", title: "Selembao"},
]

export const appAssetsURL = `${process.env.REACT_APP_BASE_URL}files`


export const hoursData = [
    {id: 1, title: "1H"},
    {id: 2, title: "2H"},
    {id: 3, title: "3H"},
    {id: 4, title: "4H"},
    {id: 5, title: "5H"},
    {id: 6, title: "6H"},
    {id: 7, title: "7H"},
    {id: 8, title: "8H"},
    {id: 9, title: "9H"},
    {id: 10, title: "10H"},
    {id: 11, title: "11H"},
    {id: 12, title: "12H"},
    {id: 13, title: "13H"},
    {id: 14, title: "14H"},
    {id: 15, title: "15H"},
    {id: 16, title: "16H"},
    {id: 17, title: "17H"},
    {id: 18, title: "18H"},
    {id: 19, title: "19H"},
    {id: 20, title: "20H"},
    {id: 21, title: "21H"},
    {id: 22, title: "22H"},
    {id: 23, title: "23H"},
    {id: 24, title: "24H"},
]
