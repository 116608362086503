import {FaBars} from "react-icons/fa"
import {BsBoxArrowLeft, BsFillFilePersonFill} from "react-icons/bs"
import {AvatarProfile, Dropdown} from "../index";
import {routes, appAssetsURL} from "../../constants";
import {useContext} from "react";
import {UserContext} from '../../contexts/UserContext'
import {images} from "../../assets/images";
import {removeAuthData} from "../../utils";
import {UseLogout} from "../../services";
import {SiPowerpages} from "react-icons/si";


type PropsType = {
    toggleSideBar: () => void
}

const HeaderNavbar = (props: PropsType) => {
    const {toggleSideBar} = props
    const {currentUser: {user: connectedUser}} = useContext(UserContext)
    const {mutate: userLogout} = UseLogout()

    const logout = () => {
        userLogout()
        removeAuthData()
        window.location.href = routes.login
    }

    const menus = [
        [
            {
                type: "default",
                title: <AvatarProfile
                    avatar={connectedUser && connectedUser?.avatar ? `${appAssetsURL}/${connectedUser.avatar}` : images.defaultAvatar}
                    title={connectedUser ? connectedUser?.name : ""}
                    subTitle={connectedUser ? connectedUser?.role_title : ""}/>
            },
        ],
        [
            {
                type: "custom",
                menuItem: {icon: <BsFillFilePersonFill/>, link: routes.profile, title: "Mon Profil"}
            },
        ],
        [
            {type: "custom", menuItem: {icon: <BsBoxArrowLeft/>, onClick: logout, title: "Déconnexion"}}
        ]
    ]

    return (
        <div className="h-12 w-full fixed top-0 z-50  flex justify-between items-center px-5 bg-sky-700 text-white">
            <div className="flex flex-row items-center cursor-pointer" onClick={() => toggleSideBar()}>
                <FaBars className="font-black text-2xl"/>
                <div className="font-black text-2xl ml-4">
                    <SiPowerpages className="inline" /> GECAO
                </div>
            </div>
            <div className="cursor-pointer">
                <Dropdown
                    title="Options"
                    position="right"
                    menus={menus}
                    type="custom"
                    titleComponent={<img
                        src={connectedUser && connectedUser.avatar ? `${appAssetsURL}/${connectedUser.avatar}` : images.defaultAvatar}
                        className="rounded-full shadow-md border border-white w-9 "
                        alt="user avatar"/>}
                />
            </div>
        </div>
    )
}

export default HeaderNavbar
