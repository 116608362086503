import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {BsArrowsAngleExpand, BsPencil, BsTrash} from "react-icons/bs";
import {RowPropsType} from "../../../types";
import {useModalConfirm} from "../../../contexts";
import {UseGetFeeders, UseUpdateFeederCategory} from "../../../services";
import {AppToast, HasPermissions} from "../../../helpers";
import {appPermissions, routes} from "../../../constants";
import {BlockingLoader, DataTable, ItemStatus, LineInfo, PageTitleMenu} from "../../../components";
import {formatDate} from "../../../utils";



type PropsType = {
    item?: any
    onDelete?: () => void
    onEditClick?: (item?: any) => void
    isModal?: boolean
}

const FeederTableRow = (props: RowPropsType) => {
    const {isOdd, item} = props

    return (
        <tr
            className={`cursor-pointer appTrHover text-[13px] ${isOdd ? 'appTrOdd' : 'appTr'}`}>
            <td  className="appTd font-semibold text-blue-500">{item.title}</td>
            <td  className="appTd">{item.direction_title}</td>
            <td  className="appTd">{item.substation_title}</td>
            <td  className="appTd">{item.section}</td>
            <td  className="appTd">{item.smva}</td>
        </tr>
    )
}

const FeederCategoryDetail = (props: PropsType) => {
    const {onDelete, onEditClick, isModal} = props
    const navigate = useNavigate()
    const {state} = useLocation()
    const item = props?.item || state?.item
    const {modalConfirm} = useModalConfirm()

    const {isLoading: isDeleting, mutate: deleteData, data: deleteResult} = UseUpdateFeederCategory()
    const {isLoading: isGettingFeeders, data: feeders} = UseGetFeeders({category_id: item?.id})

    const headMenus = [
        {
            title: "Modifier",
            onClick: onEditClick ? () => onEditClick(item) : () => navigate(`${routes.feedersUpdate}/${item.id}`, {state: {item}}),
            permissions: HasPermissions(appPermissions.feederCategories, "updater"),
            className: 'bg-gray-600 hover:bg-gray-800 text-white',
            icon: <BsPencil/>
        },
        {
            title: "Supprimer",
            onClick: () => deleteItem(),
            permissions: HasPermissions(appPermissions.feederCategories, "deleter"),
            icon: <BsTrash/>,
            className: 'bg-red-600 hover:bg-red-800 text-white'
        },
    ]
    const FeedertableHead = [
        {title: "Désignation"},
        {title: "Direction"},
        {title: "Sous station"},
        {title: "Section"},
        {title: "Intens. Nominale"},
    ]

    useEffect(() => {
        if (deleteResult) {
            if (deleteResult.error) {
                AppToast.error(deleteResult?.message)
                return
            } else {
                AppToast.success("Catégorie de Feeder supprimée avec succès")
                if (onDelete) onDelete()
                if(!isModal) navigate(routes.feedersList, {state: {refetch: true}})
                return;
            }
        }
    }, [deleteResult])

    const deleteItem = async () => {
        if (await modalConfirm()) {
            deleteData({id: item.id, status: "-1"})
        }
    }

    return (
        <div>
            <PageTitleMenu
                title={<span className="font-light text-slate-800">Détail Catégorie Feeder</span>}
                rightMenus={isModal ? [
                    ...headMenus,
                    ...[{
                        title: "Agrandir",
                        onClick: () => navigate(`${routes.feederCategoriesDetail}/${item.id}`, {state: {item}}),
                        permissions: HasPermissions(appPermissions.feederCategories, "detailer"),
                        icon: <BsArrowsAngleExpand/>,
                    }]] : headMenus}
                hasBackIcon={!isModal}
            />

            <BlockingLoader isLoading={isDeleting} label="Exécution..."/>


            <table className="mt-4">
                <tbody>
                <LineInfo titleClass="!text-left" title="Désignation" description={item.title}/>
                <LineInfo titleClass="!text-left" title="Commentaire" description={item.description}/>
                <LineInfo titleClass="!text-left" title="Statut" description={<ItemStatus item={item}/>}/>
                <LineInfo titleClass="!text-left" title="Créé le" description={formatDate(item?.created_at)}/>
                </tbody>
            </table>

            <div className="mb-2 font-bold mt-4">Les Feeders ({feeders?.data?.length})</div>
            <DataTable
                tableHead={FeedertableHead}
                data={feeders?.data}
                isLoading={isGettingFeeders}
                TableRow={FeederTableRow}
                hasSearchInput={false}
                hasMassAction={false}
            />
        </div>
    )
}


export default FeederCategoryDetail
