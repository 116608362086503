import React from "react";
import {ItemType} from "../../types";
import {Badge} from "../index";

type PropsType= {
    item: ItemType
}

const ItemStatus = ({item}: PropsType) => {
    let render =  <Badge title="ACTIF" className="bg-green-700" />
    if(item.status?.toString() === '0'){
        render = <Badge title="BLOQUÉ" className="bg-red-700" />
    }
    return render
}

export default ItemStatus
