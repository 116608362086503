import {FormatUrlParams, GetQueryKey} from "../../utils";
import {useMutation, useQuery} from "react-query";
import {http} from "../index";
import {cacheTime, endpoints} from "../../constants";
import {ChangeStatusBodyType, DefaultBodyType} from "../../types";

type OptionsType = {
    id?: string | number
    direction_id?: string | number
    sub_station_id?: string | number
    category_id?: string | number
    status?: string
}


export const UseGetFeeders = (options?: OptionsType) =>
    useQuery(GetQueryKey("feeders", options), async () => http.get(`${endpoints.feeders}${FormatUrlParams(options)}`), {staleTime: cacheTime.long});

export const UseAddFeeder = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.feeders, body));

export const UseUpdateFeeder = () =>
    useMutation(async (body: DefaultBodyType) => http.post(`${endpoints.feeders}/${body.id}`, body));

export const UseImportFeeders = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.feedersImport, body, { headers: {'content-type': 'multipart/form-data'}}));

export const UseChangeFeedersStatus = () =>
    useMutation(async (body: ChangeStatusBodyType) => http.post(endpoints.feedersChangeStatus, body));

export const UseGetFeederCategories = (options?: OptionsType) =>
    useQuery(GetQueryKey("feederCategories", options), async () => http.get(`${endpoints.feederCategories}${FormatUrlParams(options)}`), {staleTime: cacheTime.long});

export const UseAddFeederCategory = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.feederCategories, body));

export const UseUpdateFeederCategory = () =>
    useMutation(async (body: DefaultBodyType) => http.post(`${endpoints.feederCategories}/${body.id}`, body));

export const UseImportFeederCategories = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.feederCategoriesImport, body, { headers: {'content-type': 'multipart/form-data'}}));

export const UseChangeFeederCategoriesStatus = () =>
    useMutation(async (body: ChangeStatusBodyType) => http.post(endpoints.feederCategoriesChangeStatus, body));
