import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import axiosRetry from 'axios-retry';
import fileDownload from 'js-file-download';
import {AppStorage} from '../utils';
import {AppToast} from "../helpers";
import {ResponseBodyType} from "../types";
import {routes, localStorageKeys} from "../constants";


const {REACT_APP_BASE_URL} = process.env

const token = AppStorage.getItem(localStorageKeys.token);

let headers: any = {
    'content-type': 'application/json',
    "Auth-Token": token
};

const axiosInstance: AxiosInstance = axios.create({
    baseURL: REACT_APP_BASE_URL,
    headers,
    timeout: 30000,
    withCredentials: true,
})

const authInterceptor = (config: any) => {
    const newCongif = config;
    if (token) {
        newCongif.headers.Authorization = `Bearer ${token ? token : ''}`;
    }
    return newCongif;
};

axiosRetry(axiosInstance, {
    retries: 3,
    shouldResetTimeout: true,
    retryDelay: retryCount => {
        return retryCount * 2000; // time interval between retries
    },
    retryCondition: (error: any) => {
        return (
            error == 'AxiosError: Network Error'
        );
    },
});


axiosInstance.interceptors.request.use(authInterceptor, error => {
    return Promise.reject(error);
});

axiosInstance.interceptors.request.use(
    config => config,
    error => Promise.reject('Request Error --> ' + error),
);

// const parseRequestData = (data: string) => {
//     try {
//         return JSON.parse(data)
//     }catch (e) {
//         return {}
//     }
// }

async function response(response: AxiosResponse<any>): Promise<any> {
    if(typeof response.data !== "object") {
        AppToast.warning("Oups! Erreur inattendue. Réessayez SVP.")
        return false
    }
    return Promise.resolve(response.data)
}

axiosInstance.interceptors.response.use(
    response,
    err => {
        console.log(err, err.response)
        if(!err || !err.response) {
            AppToast.warning("Problème de connexion, réessayez SVP.")
            console.log("No response", err)
            return false
        }
        if(err.response.status === 419 || err.response.status === 403){
            AppToast.warning("Votre session a expiré, reconnectez-vous SVP.")
            AppStorage.clearAll()
            window.location.href = routes.login
            return false
        }
        if(err.response.status !== 200) {
            AppToast.error(err.response.data.message)
            return {
                error: true,
                status: err.response.status,
                message: err.response.data.message || "Oups! Erreur inattendue. Réessayez SVP."
            }
        }
        return err.response
    }
)


const get = async (url: string, config?: AxiosRequestConfig<any>): Promise<any> => {
    return axiosInstance.get(url, config)
}

const post = async (url: string, data?: any, config?: AxiosRequestConfig<any>): Promise<ResponseBodyType> => {
    return await axiosInstance.post(url, data, config)
}

const put = async (url: string, data?: any, config?: AxiosRequestConfig<any>): Promise<ResponseBodyType> => {
    return await axiosInstance.put(url, data, config)
}

const _delete = async (url: string, config?: AxiosRequestConfig<any>): Promise<ResponseBodyType> => {
    return await axiosInstance.delete(url, config)
}

const patch = async (url: string, data?: any, config?: AxiosRequestConfig<any>): Promise<ResponseBodyType> => {
    return await axiosInstance.patch(url, data, config)
}

const fetchDownload = async (url: string, filename: string) => {
    const myHeaders = new Headers();
    myHeaders.append("Auth-Token", token || "");
    const res = await fetch(`${REACT_APP_BASE_URL}${url}`, {method: 'GET', headers: myHeaders})
    const blob = await res.blob()
    if(blob.type === "" || blob.type !== "application/pdf") {
        AppToast.error("Une erreur est survenue ! Ressayez SVP.")
        return
    }
    fileDownload(blob, filename);
}

const methods = {
    get,
    post,
    put,
    patch,
    delete: _delete,
    fetchDownload
};

export default methods;
