import {FormActionType, ItemType} from "../../types"
import {Controller, FieldValues, useForm} from "react-hook-form";
import {AppButton, AppInput, AppSelect, PageTitleMenu} from "../../components";
import {
    UseAddCabine, UseGetCvs,
    UseGetFeeders,
    UseUpdateCabine,
} from "../../services";
import {useEffect} from "react";
import {AppToast} from "../../helpers";
import {cabinesRegomeData, cabinesTypeData, communesData, routes, statesData} from "../../constants";
import {useLocation, useNavigate} from "react-router-dom";

type PropsType = {
    action?: FormActionType
    onValidate?: (newItem?: ItemType) => void
    item?: ItemType
    isModal?: boolean
}

const CabineForm = (props: PropsType) => {
    const {action, isModal} = props
    const isUpdate = action === 'update'
    const {state} = useLocation()
    const navigate = useNavigate()
    const item = props?.item || state?.item

    const {isLoading: isAdding, mutate: addItem, data: addResult} = UseAddCabine()
    const {isLoading: isUpdating, mutate: updateItem, data: updateResult} = UseUpdateCabine()
    const {isLoading: isGettingFeeders, data: feeders} = UseGetFeeders()
    const {isLoading: isGettingCvs, data: cvs} = UseGetCvs()

    const {
        control,
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        mode: 'onSubmit', defaultValues: {
            title: isUpdate && item ? item?.title : '',
            feeder: isUpdate && item ? item?.feeder_title : '',
            cvs: isUpdate && item ? item?.cvs_title : '',
            power: isUpdate && item ? item?.power : '',
            tension: isUpdate && item ? item?.tension : '',
            quarter: isUpdate && item ? item?.quarter : '',
            street: isUpdate && item ? item?.street : '',
            mt_mark: isUpdate && item ? item?.mt_mark : '',
            mt_calibre: isUpdate && item ? item?.mt_calibre : '',
            bt_mark: isUpdate && item ? item?.bt_mark : '',
            bt_calibre: isUpdate && item ? item?.bt_calibre : '',
            type: isUpdate && item ? item?.type : '',
            regime: isUpdate && item ? item?.regime : '',
            general_state: isUpdate && item ? item?.general_state : '',
            roof_state: isUpdate && item ? item?.roof_state : '',
            floor_state: isUpdate && item ? item?.floor_state : '',
            aeration: isUpdate && item ? item?.aeration : '',
            cable_state: isUpdate && item ? item?.cable_state : '',
            commune: isUpdate && item ? item?.commune : '',
            mt_protection: isUpdate && item ? item?.mt_protection : '',
            bt_protection: isUpdate && item ? item?.bt_protection : '',
        },
    })

    useEffect(() => {
        if (addResult) {
            if (addResult.error) {
                AppToast.error(addResult.message)
                return
            } else {
                AppToast.success("Cabine enregistrée avec succès !")
                if (!isModal) navigate(routes.cabinesList, {state: {refetch: true}})
                return
            }
        }
    }, [addResult])

    useEffect(() => {
        if (updateResult) {
            if (updateResult.error) {
                AppToast.error(updateResult.message)
                return
            } else {
                AppToast.success("Cabine modifiée avec succès !")
                if (!isModal) navigate(routes.cabinesList, {state: {refetch: true}})
                return
            }
        }
    }, [updateResult])

    const onSubmit = (data: FieldValues) => {
        const body = {
            ...data,
            feeder: null,
            feeder_id: data.feeder.id,
            cvs: null,
            cvs_id: data.cvs.id,
            type: data.type.id,
            regime: data.regime.id,
            general_state: data.general_state.id,
            roof_state: data.roof_state.id,
            floor_state: data.floor_state.id,
            aeration: data.aeration.id,
            cable_state: data.cable_state.id,
            commune: data.commune.id,
            mt_protection: data.mt_protection.id,
            bt_protection: data.bt_protection.id,
        }
        const {feeder, cvs, ...rest} = body;
        if (isUpdate) updateItem({...rest, id: item?.id})
        else addItem(rest)
    }

    return (
        <div>
            <PageTitleMenu
                title={<span>{isUpdate ? 'Modifier' : 'Ajouter'} une  Cabine</span>}
                hasBackIcon={!isModal}
            />

            <form className='mt-4 grid grid-cols-3 gap-x-14' onSubmit={handleSubmit(onSubmit)}>
                <div className="col-span-1">
                    <div className="text-xl mb-4 font-bold">Detail de la Cabine</div>
                    <AppInput
                        {...register('title', {required: 'Entrez la désignation'})}
                        type='text'
                        name='title'
                        label='Désignation'
                        error={!!(errors && errors['title'])}
                        errorMessage={errors['title']?.message?.toString()}
                        required
                    />
                    <div className="grid grid-cols-2 gap-x-8">
                        <Controller
                            control={control}
                            name="feeder"
                            rules={{
                                required: {value: true, message: "Selectionnez un feeder"},
                            }}
                            render={({field}) => (
                                <AppSelect
                                    {...field}
                                    label="Feeder"
                                    name="feeder"
                                    required
                                    text="title"
                                    value="id"
                                    selectedValue={isUpdate ? item?.feeder_title : ""}
                                    isLoading={isGettingFeeders}
                                    data={feeders?.data}
                                    error={!!(errors && errors["feeder"])}
                                    errorMessage={errors["feeder"]?.message?.toString()}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="cvs"
                            rules={{
                                required: {value: true, message: "Selectionnez un CVS"},
                            }}
                            render={({field}) => (
                                <AppSelect
                                    {...field}
                                    label="CVS"
                                    name="cvs"
                                    required
                                    text="title"
                                    value="id"
                                    selectedValue={isUpdate ? item?.cvs_title : ""}
                                    isLoading={isGettingCvs}
                                    data={cvs?.data}
                                    error={!!(errors && errors["cvs"])}
                                    errorMessage={errors["cvs"]?.message?.toString()}
                                />
                            )}
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-x-8">
                        <AppInput
                            {...register('power', {required: 'Entrez la puissace'})}
                            type='text'
                            name='power'
                            label='Puissance'
                            error={!!(errors && errors['power'])}
                            errorMessage={errors['power']?.message?.toString()}
                            required
                            leftIcon={<span className="absolute right-0 top-0 text-sm font-bold">KVA</span>}
                        />
                        <AppInput
                            {...register('tension', {required: 'Entrez la tension'})}
                            type='text'
                            name='tension'
                            label='Tension'
                            error={!!(errors && errors['tension'])}
                            errorMessage={errors['tension']?.message?.toString()}
                            required
                            leftIcon={<span className="absolute right-0 top-0 text-sm font-bold">KV</span>}
                        />
                    </div>
                    <Controller
                        control={control}
                        name="type"
                        rules={{
                            required: {value: true, message: "Selectionnez un type"},
                        }}
                        render={({field}) => (
                            <AppSelect
                                {...field}
                                label="Type"
                                name="type"
                                required
                                text="title"
                                value="id"
                                selectedValue={isUpdate ? item?.type : ""}
                                data={cabinesTypeData}
                                errorMessage={errors["type"]?.message?.toString()}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="regime"
                        rules={{
                            required: {value: true, message: "Selectionnez un regime"},
                        }}
                        render={({field}) => (
                            <AppSelect
                                {...field}
                                label="Regime"
                                name="regime"
                                required
                                text="title"
                                value="id"
                                selectedValue={isUpdate ? item?.substation_title : ""}
                                data={cabinesRegomeData}
                                errorMessage={errors["regime"]?.message?.toString()}
                            />
                        )}
                    />
                </div>

                <div>
                    <div className="text-xl mb-4 font-bold">Etat de la Cabine</div>
                    <Controller
                        control={control}
                        name="general_state"
                        render={({field}) => (
                            <AppSelect
                                {...field}
                                label="Etat de la cabine"
                                name="general_state"
                                required
                                text="title"
                                value="id"
                                selectedValue={isUpdate ? item?.general_state : ""}
                                data={statesData}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="floor_state"
                        render={({field}) => (
                            <AppSelect
                                {...field}
                                label="Pavement"
                                name="floor_state"
                                required
                                text="title"
                                value="id"
                                selectedValue={isUpdate ? item?.floor_state : ""}
                                data={statesData}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="roof_state"
                        render={({field}) => (
                            <AppSelect
                                {...field}
                                label="Toiture"
                                name="roof_state"
                                required
                                text="title"
                                value="id"
                                selectedValue={isUpdate ? item?.roof_state : ""}
                                data={statesData}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="aeration"
                        render={({field}) => (
                            <AppSelect
                                {...field}
                                label="Aération"
                                name="aeration"
                                required
                                text="title"
                                value="id"
                                selectedValue={isUpdate ? item?.aeration : ""}
                                data={statesData}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="cable_state"
                        render={({field}) => (
                            <AppSelect
                                {...field}
                                label="Etat Cable de sortie"
                                name="cable_state"
                                required
                                text="title"
                                value="id"
                                selectedValue={isUpdate ? item?.cable_state : ""}
                                data={statesData}
                            />
                        )}
                    />
                </div>


                <div>
                    <div className="text-xl mb-4 font-bold">Autres details</div>

                    <div className="grid grid-cols-2 gap-x-4">
                        <div className="col-span-2 font-bold">Adresse</div>
                        <div className="col-span-2">
                            <Controller
                                control={control}
                                name="commune"
                                render={({field}) => (
                                    <AppSelect
                                        {...field}
                                        placeholder="Commune"
                                        name="commune"
                                        text="title"
                                        value="id"
                                        selectedValue={isUpdate ? item?.commune : ""}
                                        data={communesData}
                                    />
                                )}
                            />
                        </div>
                        <AppInput
                            {...register('quarter')}
                            type='text'
                            name='quarter'
                            placeholder='Quartier'
                        />
                        <AppInput
                            {...register('street')}
                            type='text'
                            name='street'
                            placeholder='No, avenue'
                        />
                    </div>

                    <div className="grid grid-cols-2 gap-x-4">
                        <div className="col-span-2 font-bold">MT</div>
                        <div className="col-span-2">
                            <Controller
                                control={control}
                                name="mt_protection"
                                render={({field}) => (
                                    <AppSelect
                                        {...field}
                                        placeholder="Protection du MT"
                                        name="mt_protection"
                                        required
                                        text="title"
                                        value="id"
                                        selectedValue={isUpdate ? item?.general_state : ""}
                                        data={statesData}
                                    />
                                )}
                            />
                        </div>
                        <AppInput
                            {...register('mt_mark')}
                            type='text'
                            name='mt_mark'
                            placeholder='Marque MT'
                        />
                        <AppInput
                            {...register('mt_calibre')}
                            type='text'
                            name='mt_calibre'
                            placeholder='Calibre MT'
                        />
                    </div>

                    <div className="grid grid-cols-2 gap-x-4">
                        <div className="col-span-2 font-bold">BT</div>
                        <div className="col-span-2">
                            <Controller
                                control={control}
                                name="bt_protection"
                                render={({field}) => (
                                    <AppSelect
                                        {...field}
                                        placeholder="Protection du BT"
                                        name="bt_protection"
                                        required
                                        text="title"
                                        value="id"
                                        selectedValue={isUpdate ? item?.bt_protection : ""}
                                        data={statesData}
                                    />
                                )}
                            />
                        </div>
                        <AppInput
                            {...register('bt_mark')}
                            type='text'
                            name='bt_mark'
                            placeholder='Marque BT'
                        />
                        <AppInput
                            {...register('bt_calibre')}
                            type='text'
                            name='bt_calibre'
                            placeholder='Calibre BT'
                        />
                    </div>

                    <div className='text-center mt-5'>
                        <AppButton
                            type='submit'
                            label='Valider'
                            bgColor='bg-cyan-600 hover:bg-cyan-900'
                            className="w-full"
                            isLoading={isAdding || isUpdating}
                        />
                    </div>
                </div>


            </form>
        </div>
    )
}

export default CabineForm
