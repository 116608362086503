import {useEffect} from "react";
import {appPermissions, routes} from "../../constants";
import {BlockingLoader, DataTable, ItemStatus, LineInfo, PageTitleMenu} from "../../components";
import {useLocation, useNavigate} from "react-router-dom";
import {useModalConfirm} from "../../contexts";
import {BsArrowsAngleExpand, BsPencil, BsTrash} from "react-icons/bs";
import {AppToast, HasPermissions} from "../../helpers";
import {UseGetSubStations, UseUpdateDirection} from "../../services";
import {formatDate} from "../../utils";
import {SubStationTableRow} from "../index";

type PropsType = {
    item?: any
    onDelete?: () => void
    onEditClick?: (item?: any) => void
    isModal?: boolean
}

const DirectionDetail = (props: PropsType) => {
    const {onDelete, onEditClick, isModal} = props
    const navigate = useNavigate()
    const {state} = useLocation()
    const item = props?.item || state?.item
    const {modalConfirm} = useModalConfirm()

    const {isLoading: isDeleting, mutate: deleteData, data: deleteResult} = UseUpdateDirection()
    const {
        isLoading: isGettingSubstations,
        data: substatations,
        refetch: reGetSubStations
    } = UseGetSubStations({direction_id: item?.id})

    const tableHead = [
        {title: "Désignation"},
        {title: "Direction"},
        {title: "Commentaire"},
        {title: "Statut", className: "w-[100px]"},
    ]
    const headMenus = [
        {
            title: "Modifier",
            onClick: onEditClick ?
                () => onEditClick(item) :
                () => navigate(`${routes.directionsUpdate}/${item.id}`, {state: {item}}),
            permissions: HasPermissions(appPermissions.directions, "updater"),
            className: 'bg-gray-600 hover:bg-gray-800 text-white',
            icon: <BsPencil/>
        },
        {
            title: "Supprimer",
            onClick: () => deleteItem(),
            permissions: HasPermissions(appPermissions.directions, "deleter"),
            icon: <BsTrash/>,
            className: 'bg-red-600 hover:bg-red-800 text-white'
        },
    ]

    useEffect(() => {
        if (deleteResult) {
            if (deleteResult.error) {
                AppToast.error(deleteResult?.message)
                return
            } else {
                AppToast.success("Direction supprimée avec succès")
                if (onDelete) onDelete()
                if(!isModal) navigate(routes.directionsList, {state: {refetch: true}})
                return;
            }
        }
    }, [deleteResult])

    const deleteItem = async () => {
        if (await modalConfirm()) {
            deleteData({id: item.id, status: "-1"})
        }
    }

    return (
        <div>
            <PageTitleMenu
                title={<span className="font-light text-slate-800">Détail Direction</span>}
                rightMenus={isModal ? [
                    ...headMenus,
                    ...[{
                        title: "Agrandir",
                        onClick: () => navigate(`${routes.directionsDetail}/${item.id}`, {state: {item}}),
                        permissions: HasPermissions(appPermissions.directions, "deleter"),
                        icon: <BsArrowsAngleExpand/>,
                    }]] : headMenus}
                hasBackIcon={!isModal}
            />

            <BlockingLoader isLoading={isDeleting} label="Exécution..."/>


            <table className="mt-4">
                <tbody>
                <LineInfo titleClass="!text-left" title="Désignation" description={item.title}/>
                <LineInfo titleClass="!text-left" title="Adresse" description={item.address}/>
                <LineInfo titleClass="!text-left" title="Statut" description={<ItemStatus item={item}/>}/>
                <LineInfo titleClass="!text-left" title="Créé le" description={formatDate(item?.created_at)}/>
                </tbody>
            </table>

            <div className="mb-2 font-bold mt-4">Les Sous Stations ({substatations?.data?.length})</div>
            <DataTable
                tableHead={tableHead}
                data={substatations?.data}
                isLoading={isGettingSubstations}
                TableRow={SubStationTableRow}
                hasMassAction={false}
                hasSearchInput={false}
            />
        </div>
    )
}

export default DirectionDetail
