import {FormatUrlParams, GetQueryKey} from "../../utils";
import {useMutation, useQuery} from "react-query";
import {http} from "../index";
import {cacheTime, endpoints} from "../../constants";
import {ChangeStatusBodyType, DefaultBodyType} from "../../types";

type OptionsType = {
    id?: string | number
    status?: string
}


export const UseGetDirections = (options?: OptionsType) =>
    useQuery(GetQueryKey("directions", options), async () => http.get(`${endpoints.directions}${FormatUrlParams(options)}`), {staleTime: cacheTime.long});

export const UseAddDirection = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.directions, body));


export const UseUpdateDirection = () =>
    useMutation(async (body: DefaultBodyType) => http.post(`${endpoints.directions}/${body.id}`, body));

export const UseImportsDirections = () =>
    useMutation(async (body: DefaultBodyType) => http.post(endpoints.directionsImport, body, { headers: {'content-type': 'multipart/form-data'}}));

export const UseChangeDirectionsStatus = () =>
    useMutation(async (body: ChangeStatusBodyType) => http.post(endpoints.directionsChangeStatus, body));
