import {Spinner} from "../index";

type PropsType = {
    isLoading: boolean
}

const TableLoader = ({isLoading}: PropsType) => {
    return isLoading ? (
        <div className="text-center mt-10"><Spinner /></div>
    ) : null
}

export default TableLoader
