import {appPermissions, perturbationsTypes, routes} from "../../constants";
import {TbBuildingBroadcastTower} from "react-icons/tb";
import {BiSolidCalendarExclamation, BiSolidUserBadge} from "react-icons/bi";
import {
    BsBarChartFill, BsBarChartSteps, BsFillBuildingFill,
    BsFillBuildingsFill,
    BsFillCalendar2XFill,
    BsFillCalendar3WeekFill,
    BsFillCalendarFill,
    BsFillCalendarMinusFill, BsFillExclamationOctagonFill,
    BsFillGearFill, BsFillPeopleFill,
    BsFillPieChartFill,
    BsGraphUp, BsGraphUpArrow, BsGrid3X3,
    BsGrid3X3Gap, BsHouseExclamation,
    BsLayoutWtf,
    BsPeopleFill,
    BsWrenchAdjustable
} from "react-icons/bs";
import {AppInput, HomeMenuItem, HomeSmallMenuItem, PageTitleMenu} from "../../components";
import {FaBuildingWheat} from "react-icons/fa6";
import {HiOutlineBuildingLibrary} from "react-icons/hi2";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {MdWarehouse, MdWebStories} from "react-icons/md";

const Home = () => {
    const menus: any[] = [
        {
            index: 1,
            title: "Installations",
            icon: <TbBuildingBroadcastTower/>,
            onClick: () => onMenuItemClick(routes.profile, 1),
            permission: appPermissions.none,
            bg: "bg-yellow-600",
            subMenus: [
                {
                    icon: <BsFillBuildingsFill/>,
                    title: "Directions",
                    onClick: () => onMenuItemClick(routes.directionsList),
                    permission: appPermissions.directions,
                },
                {
                    icon: <BsFillBuildingFill/>,
                    title: "Sous stations",
                    onClick: () => onMenuItemClick(routes.substationsList),
                    permission: appPermissions.substations,
                },
                {
                    icon: <FaBuildingWheat/>,
                    title: "Feeders",
                    onClick: () => onMenuItemClick(routes.feedersList),
                    permission: appPermissions.feeders,
                },
                {
                    icon: <HiOutlineBuildingLibrary/>,
                    title: "Cabines",
                    onClick: () => onMenuItemClick(routes.cabinesList),
                    permission: appPermissions.cabines,
                }
            ]
        },
        {
            index: 2,
            title: "Perturbations",
            icon: <BiSolidCalendarExclamation/>,
            onClick: () => onMenuItemClick("/", 2),
            permission: appPermissions.none,
            bg: "bg-slate-600",
            subMenus: [
                {
                    title: "Perturbations Sous stations",
                    icon: <BsFillCalendarFill/>,
                    onClick: () => onMenuItemClick(`${routes.perturbationsList}/${perturbationsTypes.substation}`),
                    permission: appPermissions.perturbations,
                },
                {
                    title: "Perturbations Feeders",
                    icon: <BsFillCalendar2XFill/>,
                    onClick: () => onMenuItemClick(`${routes.perturbationsList}/${perturbationsTypes.feeder}`),
                    permission: appPermissions.perturbations,
                },
                {
                    title: "Perturbations Cabines",
                    icon: <BsFillCalendarMinusFill/>,
                    onClick: () => onMenuItemClick(`${routes.perturbationsList}/${perturbationsTypes.cabine}`),
                    permission: appPermissions.perturbations,
                },
                {
                    title: "Perturbations Départs",
                    icon: <BsFillCalendar3WeekFill/>,
                    onClick: () => onMenuItemClick(`${routes.perturbationsList}/${perturbationsTypes.depart}`),
                    permission: appPermissions.perturbations,
                }
            ]
        },
        {
            index: 3,
            title: "Relevés Charges",
            icon: <BsGrid3X3Gap/>,
            onClick: () => onMenuItemClick("/", 3),
            permission: appPermissions.none,
            subMenus: [
                {
                    icon: <BsGrid3X3/>,
                    title: "Relevés",
                    onClick: () => onMenuItemClick(routes.releveNew),
                    permission: appPermissions.none,
                },
                {
                    icon: <BsGraphUpArrow/>,
                    title: "Pointes",
                    onClick: () => onMenuItemClick(routes.profile),
                    permission: appPermissions.none,
                },
            ]
        },
        {
            index: 4,
            title: "Abonnés",
            icon: <BsPeopleFill/>,
            onClick: () => onMenuItemClick("/", 4),
            permission: appPermissions.none,
            bg: "bg-blue-600",
        },
        {
            index: 5,
            title: "M.O Manoeuvres",
            icon: <BsLayoutWtf/>,
            onClick: () => onMenuItemClick("/", 5),
            permission: appPermissions.none,
            bg: "bg-cyan-600",
        },
        {
            index: 6,
            title: "M.S Equipements",
            icon: <BsWrenchAdjustable/>,
            onClick: () => onMenuItemClick("/", 6),
            permission: appPermissions.none,
            bg: "bg-green-600",
        },
        {
            index: 7,
            title: "Statistiques",
            icon: <BsBarChartFill/>,
            onClick: () => onMenuItemClick("/", 7),
            permission: appPermissions.none,
            bg: "bg-purple-600",
            subMenus: [
                {
                    icon: <BsBarChartSteps/>,
                    title: "Statistiques Sous stations",
                    onClick: () => onMenuItemClick(routes.profile),
                    permission: appPermissions.none,
                },
                {
                    icon: <BsGraphUp/>,
                    title: "Statistiques Feeders",
                    onClick: () => onMenuItemClick(routes.profile),
                    permission: appPermissions.none,
                },
                {
                    icon: <BsFillPieChartFill/>,
                    title: "Bilan Energie",
                    onClick: () => onMenuItemClick(routes.profile),
                    permission: appPermissions.none,
                },
            ]
        },
        {
            index: 8,
            title: "Paramètres",
            icon: <BsFillGearFill/>,
            onClick: () => onMenuItemClick("/"),
            permission: appPermissions.none,
            bg: "bg-orange-600",
            subMenus: [
                {
                    icon: <BiSolidUserBadge/>,
                    title: "Utilisateurs",
                    onClick: () => onMenuItemClick(routes.profile),
                    permission: appPermissions.none,
                },
                {
                    icon: <MdWarehouse/>,
                    title: "Catégories de Feeders",
                    onClick: () => onMenuItemClick(routes.feederCategoriesList),
                    permission: appPermissions.feederCategories,
                },
                {
                    icon: <MdWebStories/>,
                    title: "CVS",
                    onClick: () => onMenuItemClick(routes.cvsList),
                    permission: appPermissions.cvs,
                },
                {
                    icon: <BsFillExclamationOctagonFill/>,
                    title: "Causes de Perturbations",
                    onClick: () => onMenuItemClick(routes.perturbationCausesList),
                    permission: appPermissions.perturbationCauses,
                },
                {
                    icon: <BsHouseExclamation/>,
                    title: "Natures de Perturbations",
                    onClick: () => onMenuItemClick(routes.perturbationNaturesList),
                    permission: appPermissions.perturbationsNatures,
                },
            ]
        },
    ]
    const navigate = useNavigate()
    const [stateMenus, setStateMenus] = useState(menus)
    const [searchKey, setSearchKey] = useState<string>()
    const {register, watch} = useForm({mode: "all"});

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name === "search") {
                setSearchKey(value?.search)
                const temp: any[] = menus?.filter((item: any) =>
                    item?.title?.toLowerCase()?.includes(value?.search?.toLowerCase()) ||
                    item?.subTitle?.toLowerCase()?.includes(value?.search?.toLowerCase()) ||
                    (item.subMenus && checkSubMenu(item.subMenus, value?.search))
                )
                setStateMenus(temp)
            }
        });
        return () => subscription.unsubscribe();
    }, [watch])

    const checkSubMenu = (subMenus: any[], key: string) => {
        const temp: any[] = subMenus?.filter((subItem: any) =>  subItem?.title?.toLowerCase()?.includes(key?.toLowerCase()))
        return !!temp.length
    }


    const onMenuItemClick = (path: string, key?: number) => {
        navigate(path)
    }

    return (
        <div>
            <PageTitleMenu
                title="Accueil"
                hasBorderBottom
                hasBackIcon={false}
            />

            <div className="mt-8 grid md:grid-cols-3 grid-cols-1 text-center">
                <div/>
                <div>
                    <AppInput
                        {...register("search")}
                        placeholder="Recherche"
                        name="search"
                        autoComplete="off"
                        className="!rounded-3xl px-5 focus:!w-[100%] !w-[65%] transition-all duration-200 ease-in-out text-center"
                    />
                </div>
            </div>

            {searchKey ?
                <div className="grid md:grid-cols-3 grid-cols-1">
                    <div className="col-start-2 bg-white p-8 rounded-xl grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4">
                        {
                            stateMenus.map((item, key: number) => (
                                item.subMenus ? item.subMenus.map((subItem: any, subKey: number) => (
                                        <HomeSmallMenuItem
                                            key={subKey}
                                            icon={subItem.icon}
                                            title={subItem.title}
                                            onClick={subItem.onClick}
                                        />
                                    )) :
                                    <HomeSmallMenuItem
                                        key={key}
                                        icon={item.icon}
                                        title={item.title}
                                        onClick={item.onClick}
                                    />
                            ))
                        }
                    </div>

                </div> :
                <div className="grid md:grid-cols-4 grid-cols-1 mt-8 gap-6">
                    {
                        menus.map((item, key) => (
                            <HomeMenuItem
                                key={key}
                                icon={item.icon}
                                title={item.title}
                                onClick={item.onClick}
                                subMenus={item.subMenus}
                                bg={item.bg}
                                subTitle={item.subTitle}
                            />
                        ))
                    }
                </div>
            }
        </div>
    )
}

export default Home
