import {ReactNode} from "react";

type PropsType = {
    icon: ReactNode
    title: string | ReactNode,
    bg?: string
    onClick: any
    subMenus?: any[]
}


const HomeSmallMenuItem = ({icon, title, onClick}: PropsType) => {
    return (
            <div
                onClick={onClick}
                className="flex flex-col  items-center cursor-pointer hover:bg-cyan-100 py-2 rounded transition-all duration-200 ease-in-out">
                <span className="text-2xl text-cyan-600">{icon}</span>
                <span className="text-center text-sm mt-2">{title}</span>
            </div>
    )
}

export default HomeSmallMenuItem
