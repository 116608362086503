import React, { ReactNode } from 'react'

type LineProps = {
    title: ReactNode
    description: ReactNode
    titleClass?: string
    descriptionClass?: string
}


const LineInfo = ({title, description, descriptionClass, titleClass}: LineProps) => {
    return (
        <tr className="hover:bg-blue-100">
            <td className={`${titleClass} py-2 w-[150px] text-[13px] text-right align-top`}>{title}</td>
            <td className={`${descriptionClass} pl-8 font-semibold`}>{description}</td>
        </tr>
    )
}

export default LineInfo
