import {useEffect} from "react";
import {appPermissions, routes} from "../../constants";
import { BlockingLoader, DataTable, ItemStatus, LineInfo, PageTitleMenu} from "../../components";
import {useLocation, useNavigate} from "react-router-dom";
import {useModalConfirm} from "../../contexts";
import {BsArrowsAngleExpand, BsPencil, BsTrash} from "react-icons/bs";
import {AppToast, HasPermissions} from "../../helpers";
import {UseGetCabines, UseUpdateFeeder} from "../../services";
import {formatDate} from "../../utils";
import {RowPropsType} from "../../types";

type PropsType = {
    item?: any
    onDelete?: () => void
    onEditClick?: (item?: any) => void
    isModal?: boolean
}

const CabineTableRow = (props: RowPropsType) => {
    const {isOdd, item, onItemClick} = props

    return (
        <tr
            className={`cursor-pointer appTrHover text-[13px] ${isOdd ? 'appTrOdd' : 'appTr'}`}>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd font-semibold text-blue-500">{item.title}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.cvs_title}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.power}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.tension}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.type}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.regime}</td>
            <td onClick={onItemClick ? () => onItemClick(item) : () => console.log("")}
                className="appTd">{item.general_state}</td>
        </tr>
    )
}

const FeederDetail = (props: PropsType) => {
    const {onDelete, onEditClick, isModal} = props
    const navigate = useNavigate()
    const {state} = useLocation()
    const item = props?.item || state?.item
    const {modalConfirm} = useModalConfirm()

    const {isLoading: isDeleting, mutate: deleteData, data: deleteResult} = UseUpdateFeeder()
    const {isLoading: isGettingCabines, data: cabines} = UseGetCabines({feeder_id: item?.id})

    const headMenus = [
        {
            title: "Modifier",
            onClick: onEditClick ? () => onEditClick(item) : () => navigate(`${routes.feedersUpdate}/${item.id}`, {state: {item}}),
            permissions: HasPermissions(appPermissions.feeders, "updater"),
            className: 'bg-gray-600 hover:bg-gray-800 text-white',
            icon: <BsPencil/>
        },
        {
            title: "Supprimer",
            onClick: () => deleteItem(),
            permissions: HasPermissions(appPermissions.feeders, "deleter"),
            icon: <BsTrash/>,
            className: 'bg-red-600 hover:bg-red-800 text-white'
        },
    ]
    const tableHead = [
        {title: "Désignation"},
        {title: "CVS"},
        {title: "Puissance/KVA"},
        {title: "Tension/KV"},
        {title: "Type"},
        {title: "Regime"},
        {title: "Etat Cabine"},
    ]

    useEffect(() => {
        if (deleteResult) {
            if (deleteResult.error) {
                AppToast.error(deleteResult?.message)
                return
            } else {
                AppToast.success("Feeder supprimé avec succès")
                if (onDelete) onDelete()
                if(!isModal) navigate(routes.feedersList, {state: {refetch: true}})
                return;
            }
        }
    }, [deleteResult])

    const deleteItem = async () => {
        if (await modalConfirm()) {
            deleteData({id: item.id, status: "-1"})
        }
    }

    return (
        <div>
            <PageTitleMenu
                title={<span className="font-light text-slate-800">Détail Feeder</span>}
                rightMenus={isModal ? [
                    ...headMenus,
                    ...[{
                        title: "Agrandir",
                        onClick: () => navigate(`${routes.feedersDetail}/${item.id}`, {state: {item}}),
                        permissions: HasPermissions(appPermissions.feeders, "detailer"),
                        icon: <BsArrowsAngleExpand/>,
                    }]] : headMenus}
                hasBackIcon={!isModal}
            />

            <BlockingLoader isLoading={isDeleting} label="Exécution..."/>


            <table className="mt-4">
                <tbody>
                <LineInfo titleClass="!text-left" title="Désignation" description={item.title}/>
                <LineInfo titleClass="!text-left" title="Direction" description={item.direction_title}/>
                <LineInfo titleClass="!text-left" title="Sous station" description={item.substation_title}/>
                <LineInfo titleClass="!text-left" title="Catégorie" description={item.category_title}/>
                <LineInfo titleClass="!text-left" title="Section" description={item.section}/>
                <LineInfo titleClass="!text-left" title="Intensité nominale" description={item.smva}/>
                <LineInfo titleClass="!text-left" title="Commentaire" description={item.description}/>
                <LineInfo titleClass="!text-left" title="Statut" description={<ItemStatus item={item}/>}/>
                <LineInfo titleClass="!text-left" title="Créé le" description={formatDate(item?.created_at)}/>
                </tbody>
            </table>

            <div className="mb-2 font-bold mt-4">Les Cabines ({cabines?.data?.length})</div>
            <DataTable
                tableHead={tableHead}
                data={cabines?.data}
                isLoading={isGettingCabines}
                TableRow={CabineTableRow}
                hasSearchInput={false}
                hasMassAction={false}
            />
        </div>
    )
}


export default FeederDetail
