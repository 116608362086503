import {FC, forwardRef} from "react";
import Select from 'react-select'
import {SelectFormType} from "../../types";
import InputHelperMessage from "./InputHelperMessage";

const AppSelect: FC<SelectFormType> = forwardRef((props: SelectFormType, ref: any) => {
    const {
        data,
        name,
        label,
        required,
        multiple,
        placeholder = "Selectionner...",
        selectedValue,
        selectedValueMulti = [],
        text = 'name',
        textSeparator = " ",
        value = 'id',
        textPrefix = "",
        onChange,
        errorMessage,
        isSearchable = true,
        isClearable = true,
        containerClassName,
        isLoading = false, ...rest
    } = props

    return (
        <div className={`${containerClassName} mb-1`}>
            {label && <div>{label}</div>}
            <div className="relative">
                <Select
                    {...rest}
                    ref={ref}
                    name={name}
                    options={data}
                    isSearchable={isSearchable}
                    placeholder={placeholder}
                    isMulti={multiple}
                    defaultValue={selectedValueMulti}
                    defaultInputValue={selectedValue}
                    controlShouldRenderValue
                    getOptionLabel={(option) => {
                        if (typeof text === "string") return option[text]
                        else if (typeof text === "object") {
                            let res = ""
                            text.forEach((item: string, index: number) => {
                                res += option[item] ? `${textPrefix.length ? textPrefix[index] + textSeparator : ""} ${option[item]}` + " " : ""
                            })
                            return res
                        }
                    }}
                    getOptionValue={(option) => option[value]}
                    onChange={(option) => onChange && onChange(option)}
                    isLoading={isLoading}
                    styles={
                        {
                            placeholder: (provided, state) => ({
                                ...provided,
                                fontSize: 13,

                            }),
                            control: (provided, state) => ({
                                ...provided,
                                fontWeight: "normal",
                                fontSize: 13,
                                border: "none",
                            }),
                            container: (provided, state) => ({
                                ...provided,
                                // zIndex: 4,
                            }),
                            valueContainer: (provided, state) => ({
                                ...provided,
                            }),
                        }
                    }
                    className={`${errorMessage ? 'border-red-300 focus:outline-none' : 'border-slate-400'}  appearance-none  border-2  placeholder:text-sm placeholder:font-thin placeholder:text-gray-400  w-full rounded  shadow-sm  bg-white`}
                    isClearable={isClearable}
                />
            </div>
            <InputHelperMessage message={errorMessage} type="error"/>

        </div>
    )
})

export default AppSelect
